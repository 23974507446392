<template>
  <div class="box" @click="privacidad('Acuerdo de Privacidad')">《Politica de privacidad》</div>
</template>

<script>
export default {
  name: 'agreeMent',
  data () {
    return {

    }
  },
  methods: {
    privacidad (name) {
      this.$router.push({ name: 'AgreeMent', query: { name } })
    }

  }

}
</script>
<style lang="less" scoped>
  .box{
    font-size: 0.32rem;
     display: flex;
     justify-content: center;
     align-items: center;
     color: #5E5E5E;
  }
</style>
