<template>
  <div class="content">
    <div class="headers">
      <div style="display: flex;align-items: center;">
        <img src="@/assets/leftBtn.png" alt="" class="headers-img" @click="go">
        <div class="headers-name" >Detalles del préstamo</div>
      </div>
      
      <problemFeedback :title="title"></problemFeedback>
    </div>
    <div class="mian">
      <!-- <div class="faq-box">
        <img src="@/assets/tipsTop.png" alt="" style="width: 0.4267rem;height: 0.4267rem;margin-right:0.2667rem;">
        <div class="faq-center">
           <div style="font-size: 0.3733rem;color: rgba(0,0,0,0.6);text-align: initial;">La prórroga sólo extenderá </div>
           <div style="font-size: 0.3733rem;color: rgba(0,0,0,0.6);text-align: initial;">plazo de pago</div>
        </div>
        <van-button size="large" class="btn-faq" @click="goFAQ">
          Prórroga>
        </van-button>

      </div> -->
       <div class="title-box">
        <div class="title-top">
          <div  style="display: flex;align-items: center;">
            <img :src="img" alt="" class="title-left">
            <div style="font-size: 0.48rem;color: #212121;margin-bottom: 0.1067rem;">{{ OrderDetails.laboreos }}</div>
          </div>
         
           <div class="title-right">
              <div class="right-bottom">Prórroga de plazo</div>
           </div>
           
        </div>
        <div class="title-center">
          <div class="center-left">
            <div style="font-size: 0.5333rem;font-weight: bold;color: #333333;text-align: initial;margin-bottom: 0.1333rem;" >{{ amount }}</div>
            <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
              Gasto de prórrog ($)</div>
           
          </div>
          <div class="center-right">
            <div style="font-size: 0.5333rem;font-weight: bold;color: #333333;text-align: initial;margin-bottom: 0.1333rem;">{{ delayExpiryTime }}</div>
          <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
            Nueva fecha de vencimiento</div>
       
      </div>
        </div>
        <div class="title-bottom"></div>
      
       </div>
       <van-button size="large" class="btn-top" @click="periodo">
        Confirmar la prórroga de periodo
        </van-button>
       <div class="Details" v-if="repaymentPlans?.length>0">
        <div class="Details-title">Plan de reembolso</div>
       </div>
       <div class="period" v-if="repaymentPlans?.length>0">
           <div class="period-top">
           </div>
           <div>
            <van-steps direction="vertical" :active="3" active-color="#fff" >
            

                <van-step v-for="(item,index) in repaymentPlans" :key="index" :class="{'stepstyle': index === repaymentPlans?.length-1}">
                  <template #finish-icon>
                    <div class="flag-icon"></div>
                  </template>
                  <div class="step-box">
                    <div class="step-top">
                       <div style="display: flex;align-items: start;justify-content: start;">
                        <div class="step-left">{{ item.aforca }} </div>
                        <div class="step-right"></div>
                       </div>
                    
                      <div class="step-center">{{ item.abstractivo }}</div>
                      <!-- <div class="step-center">Fecha de vencimiento</div> -->
                  
                    </div>
                    <div class="step-bottom">
                       <div>
                        <div class="step-left">
                           <div class="step-money" :class="{'underline': index !== 0}">{{ item.huidos }} </div>
                           <div class="step-num">{{ item.izamos }} Plazo<span v-if="index !== 0">s</span></div>
                        </div>
                        <div class="step-right">Importe a pagar</div>
                       </div>
                       <div>

                       </div>
                    </div>
                  </div>
                
                </van-step>
         </van-steps>
           </div>
        </div>
       <div class="Details" >
        <div class="Details-title">Detalles del gasto de prórroga</div>
       </div>
       <div class="content-box">
        <div  v-for="(item,index) in list" :key="index" >
          <div class="Details-num" v-if="showAll || (index < visibleCount && index !== 4)">
              <div class="num-left" >{{ item.aniejado }}</div>
              <div class="num-right" v-html="item.salegarais"></div>
         </div>
         <div class="Details-num"  v-else-if="index === 4">
              <div class="num-left" >{{ item.aniejado }}</div>
              <div class="num-right" v-html="item.salegarais" ></div>
         </div>
        </div>
       
       <div class="Ocultar" @click="toggleShowAll" >
          <div class="Ocultar-name">Ocultar</div>
          <van-icon name="arrow-down" class="icon"   v-if="!showAll"/>
          <van-icon name="arrow-up" class="icon" v-else />
          
         
        </div>
       </div>
      
        <agreeMent class="agreeMent"></agreeMent>
    </div>
   
    <extensionDetailsDlog ref="extensionDetailsDlog"></extensionDetailsDlog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import problemFeedback from '@/components/problemFeedback.vue'
import extensionDetailsDlog from '@/components/extensionDetailsDlog.vue'

import agreeMent from '@/components/agreeMent.vue'
import { base64ToImage } from '@/utils/baseImg'
import {getCalculateDelay} from '@/views/order/lib'
import {mapMutations,mapState } from 'vuex'
export default {
name:'orDer',
components:{agreeMent,problemFeedback,extensionDetailsDlog},

data(){
 return{
  title:"orDer",
    list:[],
    productName:'',
    img:"",
    showperiodre:false,
    OrderDetails:{},
    repaymentPlans:[],
    amount:'',
    delayExpiryTime:'',
    showAll:false,
    visibleCount: 5,
    orderNo:localStorage.getItem('orderNo')||'',
    status:null,
    loading:true,
 }
},
computed: {
    ...mapState('extensionDetails', ['orderDetails'])
  },

methods: {
  base64ToImage,
  ...mapMutations('extensionDetails', ['changeDelayExpiryTime']),
 async getCalculateDelay(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    const res=await getCalculateDelay({
      orderNo:this.orderNo, //170状态 放款成功
    })
    console.log(res,'订单详情');
  if(res.conearia==='200'){
    loadingInstance.clear()
    this.loading=false,
    this.list=res.tallecieras.envagueceis
    this.amount=res.tallecieras.propugnamos
    this.delayExpiryTime=res.tallecieras.aforca
    this.repaymentPlans=res.tallecieras.despercudidos
    this.changeDelayExpiryTime(this.delayExpiryTime)
    // this.productName=res.data.productName
    // this.img = base64ToImage(`${'data:image/png;base64,' + res.data.slogan}`)
    // this.status=res.data.status
}else{
  loadingInstance.clear()
}

  },
  go(){
    this.$router.replace({ name: 'overdue'})
  },
  // 借款协议
  loan(name){
    this.$router.push({ name: 'AgreeMent', query: { name } })
  }, 
  contrato(){
    
  },
  showperiod(){
    this.showperiodre=!this.showperiodre

  },
  goFAQ(){
    const packageName = localStorage.getItem('x-package-name')
      const token = localStorage.getItem('token')
    window.location.href = `http://47.117.39.82/app-loanmarket-h5-faq/#/FAQ?token=${token}&packageName=${packageName}`
  },
  toggleShowAll(){
    this.showAll = !this.showAll
  },
  periodo(){
    this.$managementEvents({ pageName: 'sucedere', action: 'enmadraras', extInfo: '' })
    this.$refs.extensionDetailsDlog.show = true
      this.$refs.extensionDetailsDlog.delayExpiryTime = this.delayExpiryTime
  }
 
  

  },
  mounted(){
    this.getCalculateDelay()
    this.$managementEvents({ pageName: 'sucedere', action: 'cortezo', extInfo: {loanOrderId: this.orderNo } })
    // this.OrderDetails=this.orderDetails
    if(localStorage.getItem('orderDetails')){
      this.OrderDetails=JSON.parse(localStorage.getItem('orderDetails'))
    }else{
      localStorage.setItem('orderDetails',JSON.stringify(this.orderDetails))
      this.OrderDetails=JSON.parse(localStorage.getItem('orderDetails'))
    }
    
   
   
    
   
    this.img = base64ToImage(`${'data:image/png;base64,' + this.OrderDetails.nornordeste}`)
    console.log('OrderDetails', this.OrderDetails);
  }
}
</script>

<style lang="less" scoped>
.underline{
  text-decoration: line-through;
   text-decoration-thickness: 0.0533rem; /* 设置横线的粗细 */
}
/deep/ .van-step__line {
  border: 0.033rem dashed #000000 ; /* 设置虚线样式 */
  background-color: #fff;

}
/deep/.stepstyle .van-step__line {
  border: 0.033rem dashed #fbae84 ; /* 设置虚线样式 */
  background-color: #fff;
  height:0 !important;
}
// /deep/ .van-step:last-child .van-step__line  {
//   border: none; /* 最后一个步骤没有连接线 */
// }
/deep/ .van-step--vertical .van-step__line{
  top: 0.4267rem;
    left:50%;
    width: 0.0267rem;
    height: 100%;
}
/deep/ .van-step--vertical .van-step__circle-container {
  top: 1.3rem;
  left:48.3%;
    width: 0.0267rem;
    height: 100%;
}

 .van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0;

}
.van-steps--vertical{
  padding: 0;
}

.content{
  // width: 100%;
  overflow: auto;
  // height: 21.6533rem;
  // padding: 1.1733rem 0.4267rem 0 0.4267rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .headers{
    width: 100%;
    height: 2.0533rem;
    padding: 0.9867rem 0.4267rem 0 0.5rem;
    background: #FFFFFF;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.64rem;
      position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
    .icon{
      font-size: 0.5rem;
      color: #666666;
    }
    .headers-name{
      font-size: 0.4267rem;
      color: #000000;
      font-weight: bold;
    }
    .headers-img{
      width: 0.8533rem;
      height: 0.8533rem;
      margin-right: 0.2667rem;
    }
  }
  .mian{
    margin-top:2.0533rem;
    width: 100%;
    height: calc(100vh - 2.0533rem); /* 计算剩余高度 */
    overflow:auto;
    padding: 0 0.4267rem;
    background: #FEFFFA;
    .faq-box{
      width: 100%;
      height: 1.3867rem;
      padding-left: 0.32rem;
      background: #FFF3DA;
      border-radius:0.32rem;
      display: flex;
      align-items: center;
      margin-bottom: 0.4267rem;
      .btn-faq{
        width: 2.3467rem;
        height: 0.96rem;
        background: #00CA78;
        border-radius:0.2133rem;
        font-size: 0.32rem;
        font-weight: 500;
        color: #FFFFFF;
      }
      .faq-center{
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
      }

    }
    .title-box{
      width: 100%;
     margin-top: 0.2933rem;
     margin-bottom: 0.4333rem;
      height: auto;
      border-radius: 0.64rem;
      border: 0.0267rem solid #000000;
      // border:0.0267rem solid #EAEAEA;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 0.3733rem 0 0 0;
       .title-top{
        width: 100%;
       display: flex;
       align-items: center;
       padding: 0 0.3733rem;
       justify-content: space-between;
        .title-left{
          width: 1.12rem;
          height: 1.12rem;
          margin-right: 0.4rem;
          border-radius:0.32rem;
          border: 0.0267rem solid #000000;
        }
        .title-right{
          display: flex;
          flex-direction: column;
          text-align: initial;
          .right-bottom{
            width: auto;
            height: 0.7467rem;
            display:flex;
            justify-content: center;
            padding:0 0.32rem ;
            align-items: center;
            font-weight: 500;
            background: #000000;
            border-radius:0.3733rem;
            color: #ffffff;
            font-size: 0.32rem;
          }
      
        }
       }
       .title-center{
        margin-top: 0.3733rem;
        margin-bottom: 0.0267rem;
        border-radius: 0 0 0.64rem 0.64rem;
        border-top: 0.0267rem solid #000000;
        display: flex;
        background: #F9FFDE;
        justify-content: space-between;
        .center-left{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-left: 0.8rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
         
        }
        .center-right{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-right: 0.4rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
        }
       }
      
    }
    .period{
      width: 100%;
      height: auto;
      background: #FFFFFF;
      border-radius: 0.5333rem;
      border: 0.0267rem solid #000000;
      padding: 0.5067rem 0.4267rem;
      .flag-icon{ 
          width:0.3933rem;          /* 圆的宽度 */
                height: 0.3933rem;         /* 圆的高度 */
                border: 0.0667rem solid #000000; /* 圆的边框颜色和宽度 */
                border-radius: 50%;    /* 使元素变成圆形 */
                background: #D5F64F; /* 背景透明，形成空心效果 */}
      .period-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
     
        .period-left{
          font-size: 0.3733rem;

        }
        .period-right{
          display: flex;
          align-items: center;
          font-size: 0.3733rem;
          font-weight:500;
        }
      }
      .step-box{
        display: flex;
        justify-content: space-between;
        .step-top{
          display: flex;
          flex-direction: column;
          width: 3.2rem;
          text-align: initial;
          // text-align: left;
          // margin-left: 0.8667rem;
          justify-content: start;
          // justify-content: space-between;
          align-items: center;
          margin-bottom: 0.2933rem;
          .step-left{
            margin-right: 0.1333rem;
            font-size: 0.3733rem;
            color: #000000; 
          }
          .step-center{
            width: 100%;
            font-size:0.32rem;
            color: #666666;
            font-weight: 400;
            text-align: left;
            text-decoration: line-through; 
            // margin: 0 0.1333rem 0 0.2133rem;

          }
          .step-right{
            background-image: url('@/assets/periodNEW.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
            width:1.0133rem;
            height:0.5867rem;

          }
        }
        .step-bottom{
          width: auto;
          height: 1.3867rem;
          // background: #F3F3FB;
          // margin-left: 0.33rem;
          border-radius: 0 0.32rem 0.32rem 0;
          // padding-left: 0.4rem ;
          display: flex;
          flex-direction: column;
          align-items: start;
          // justify-content: center;
          .step-left{
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #000000;
            .step-money{
              width: 1.4667rem;
              margin-right: 0.2133rem;
              text-align: left;
              color: #333333;
              font-weight: bold;
              font-size: 0.48rem;
            }
            .step-num{
             
              background-image: url('@/assets/periodBG.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
            display: flex;
            font-weight: 600;
            font-size: 0.2667rem;
            align-items: center;
            justify-content: center;
            width:1.5467rem;
            color: #333333;
            height: 0.5333rem;


            }
           
          }
          .step-right{
            text-align: left;
            font-size: 0.2933rem;
            color: #999999;

          }
        }

        
      }
    }
    .Details{
      width: 100%;
      // margin-top: 0.4533rem;
      // padding:0 0.2667rem;
      .Details-title{
        text-align:initial;
        font-weight: bold;
        font-size: 0.48rem;
        color: #3A3A47;
        margin: 0.4267rem 0 0.24rem 0 ; 
      }
    }
    .content-box{
      width: 100%;
      display: flex;
      border: 0.0267rem solid #000000;
      flex-direction: column;
      padding: 0.2667rem 0.64rem 0 0.64rem;
      border-radius:0.4267rem;
      background: #FFFFFF;
      margin-bottom: 0.64rem;
      margin-top:0.4267rem ;
      .Ocultar{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.64rem;
        margin-top:0.3067rem /* 19/37.5 */ ;
        .Ocultar-name{
          font-size: 0.3733rem;
          color: #999999;
          margin-right: 0.1333rem;
        }
        .icon{
          color: #999999;
          font-size: 0.4rem;
        }
      }

      .Details-num{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:0.4267rem;
      padding-bottom:0.4267rem;
      // border-bottom:0.0267rem solid #ddd; /* 所有元素都有下横线 */
      .num-left{
        font-weight: 500;
        font-size: 0.3467rem;
        color: #000000;
      }
      .num-right{
        font-weight: bold;
        font-size: 0.4267rem;
        color: #000000;
      }
    }
    }
    .btn-top{
        width: 8.9333rem;
        height: 1.44rem;
        background: #D5F64F;
        border-radius: 0.8rem;
        color: #000000;
        font-weight: bold;
        font-size:0.4267rem;
        margin: 0 0 0.56rem 0;
      }

   
  }
  .agreeMent{
    margin-bottom: 0.5333rem;
  }

 

}

</style>