<template>
  <div>
    <van-overlay :show="show" @click="show = false">
  <div class="wrapper" @click.stop>
    <div class="block" >
      <div class="content">
        <div class="content-title">
          Comentarios
        </div>
        <!-- <div class="content-line"></div> -->
        <div class="content-name">
          Deje su comentario aquí
        </div>
      </div>
    <div class="content-mian">
      <van-form  class="from">
       <van-field
            v-model="form.content"
            rows="1"
            autosize
            type="textarea"
            maxlength="200"
            show-word-limit
            class="field"
            placeholder="Por favor inserte"
        >
        <!-- <template  #left-icon>
        <img src="@/assets/customerPen.png" alt="" class="placeholder-icon" >
       </template> -->
      </van-field>
        
      <van-button type="primary" size="large" class="btn-top" @click="onSubmit" :disabled="disabled" >Enviar</van-button>
    </van-form>
    
    <div class="titl-bottom">
      Además, tiene la opción de contactar<br>
      directamente con el servicio de atención al<br>
      cliente.
    </div>
    <div class="bottom-btn">
      <div class="btn-left" :data-clipboard-text="form.vcode">{{ form.vcode }}</div>
      <div class="btn-right" @click="copy" >Copy</div>
    </div>
    </div>
    <img  src="@/assets/offcustomer.png" alt=""  @click="close" class="close-icon"/>
    </div>
   
  </div>

</van-overlay>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import { Toast } from 'vant'
import { getCustomerPhone, getComplaint } from '@/views/order/lib'

export default {
  data () {
    return {
      show: false,
      title: '',
      form: {
        content: '',
        vcode: ''

      }

    }
  },
  computed: {
    disabled () {
      return !this.form.content
    }

  },
  methods: {
    close () {
      this.form = {
        content: '',
        vcode: ''

      }
      this.show = false
    },
    async onSubmit(){
     const res=await getComplaint({
        content:this.form.content,
        title:this.title
      })
     if(res.conearia==='200'){
      Toast("Sent successfully")
      this.form = {
        content: '',
        vcode: ''

      }
      this.show = false

     }
    },
    input(){

    },
    async getCustomerPhone () {
      const res = await getCustomerPhone()
      console.log(res,'res');
      this.form.vcode = res.tallecieras.acidularemos
    },
    copy () {
      if (this.copynum) {
        this.copynum.destroy()
      }
      this.copynum = new ClipboardJS('.btn-left')
      this.copynum.on('success', (e) => {
        Toast("Copia éxito")
        e.clearSelection()
      })
      this.copynum.on('error', function (e) {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
      this.copynum.onClick({ currentTarget: document.querySelector('.btn-left') })
    }
    // 获取客服电话回显
    // async getCustomerServiceInfo () {
    //   const res = await getCustomerServiceInfo()
    //   this.form.vcode = res.data.phone
    // },
    // async onSubmit () {
    //   const res = await getcomplaint({
    //     content: this.form.content,
    //     title: this.title
    //   })
    //   if (res.code === '200') {
    //     Toast('Sent successfully')
    //     this.form = {
    //       content: '',
    //       vcode: ''

    //     }
    //     this.show = false
    //   }
    // },
    // input (value) {
    //   localStorage.setItem('form', value)
    // },
    // copy () {
    //   if (this.copynum) {
    //     this.copynum.destroy()
    //   }
    //   this.copynum = new ClipboardJS('.btnVcode')
    //   this.copynum.on('success', (e) => {
    //     Toast('Copia éxito')
    //     e.clearSelection()
    //   })
    //   this.copynum.on('error', function (e) {
    //     console.error('Action:', e.action)
    //     console.error('Trigger:', e.trigger)
    //   })
    //   this.copynum.onClick({ currentTarget: document.querySelector('.btnVcode') })
    // }

  },
  created () {
  },
  mounted () {
  }

}
</script>

<style lang="less" scoped>

.van-cell{
  background: #FFFFFF;
}
::v-deep .van-cell::after{
  position:static;
}
::v-deep .van-field__word-limit {
margin-top:-0.2667rem;
}
::v-deep .van-field__control::-webkit-input-placeholder{
  font-size: 0.3467rem;
  font-weight: bold;
  color: #999999; /* 设置占位符文本的颜色 */
}
::v-deep .van-field__control {
  color: #000;
  // font-weight:700;
  height: 2.1267rem  !important;
}
.from{
  width: 100%;
 display: flex;
 flex-direction: column;
align-items: center;
.placeholder-icon{
  margin-top:0.1333rem;
  width: 0.4rem;
  height: 0.4rem;

}
}
.field{
  height: 2.8rem ;
  border: 0.0267rem solid #000000;
  border-radius: 0.64rem;
  background: #FFFFFF;
  margin:0.2133rem 0 0.2533rem 0;
}
 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    display: flex;
    position: relative;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // margin-top: 3.9467rem;
    width: 9.0667rem;
    height: auto;
    background: #FFFFFF;
    // background-image: url(~'@/assets/customerbg.png');
    // background-size: 100% 100%;
    border-radius:0.64rem;
    padding: 0.64rem 0.8rem 0.64rem 0.8rem;
   
    
    .content{
      width: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
     .content-title{
      text-align: left;
      font-size: 0.5333rem;
      color: #000000;
      font-weight: bold;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
      margin-bottom:0.2933rem;
     }
     .content-line{
      width: 100%;
      
      height: 0;
      margin-bottom: 0.4267rem;
      border: 0.0267rem solid #EAEAEA;
     }
     .content-name{
      width: 100%;
      font-size: 0.4267rem;
      font-weight: bold;
      text-align: left;
      color: #000000;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
      // margin-bottom:0.2133rem;
     }

    }
    .content-mian{
      display: flex;
    position: relative;
    flex-direction: column;
    .titl-bottom{
      width: 100%;
      text-align: initial;
      font-size:0.32rem;
      margin-bottom: 0.3733rem;
      color: #999999;
    }
    .bottom-btn{
      display: flex;
      // padding-left: 1.28rem;
      // margin-top: -0.2667rem /* 10/37.5 */;
      justify-content: space-between;
      align-items: center;
      .btn-left{
        // display: flex;
        font-size: 0.3467rem;
        font-weight: bold;
        word-wrap: break-word;
        color: #000000;
        width: 4.16rem;
        height: 1.28rem;
        border-radius:0.4267rem;
        padding: 0.2667rem;
        text-align: left;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-wrap: wrap;
        border: 0.0267rem solid #000000;
        // height: auto;
        // margin-right: 0.1333rem;
        // border-radius: 2.6667rem;
        // padding: 0.4533rem 0 0.3733rem 0.32rem;
      }
      .btn-right{
      width:3.0133rem;
      height:1.28rem;
      background: #000000;
      border-radius:0.8rem;
      font-weight: bold;
      display: flex;
      font-size:0.3733rem;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      }
     }
    }
    .btn-top{
      background: #D5F64F;
      height: 1.0933rem;
      width:7.4667rem;
      border-radius: 2.6667rem;
      border: none;
      color: #000000;
      font-weight: bold;
      font-size: 0.4267rem;
      margin-bottom: 0.2667rem;
    }
    // .btn-top:disabled{
    //   opacity: 0.9; /* 设置禁用状态下的透明度 */
    //   background:#646b73;
    //   color: #000;
    // }
  }
  .close-icon {
    width: 0.88rem;
    height:0.88rem;
    // margin-top: 1.4133rem;
    border: none;
    top: -1.2rem;
    right:0.1rem;
    position: absolute;
  }

</style>
