import Vue from 'vue'
import VueRouter from 'vue-router'
import order from '../views/order/index.vue'
import extensionPayment from '../views/order/extensionPayment.vue'
import overdue from '../views/order/overdue.vue'
import normalRepayment from '../views/order/normalRepayment.vue'
import extensionDetails from '../views/order/extensionDetails.vue'
import agreeMent from '../views/agreeMent/index.vue'
Vue.use(VueRouter)
console.log('进来了');
const routes = [
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/extensionPayment',
    name: 'extensionPayment',
    component: extensionPayment,
  },
  {
    path: '/overdue',
    name: 'overdue',
    component: overdue,
  },
  {
    path: '/normalRepayment',
    name: 'normalRepayment',
    component: normalRepayment,
  },
  {
    path: '/extensionDetails',
    name: 'extensionDetails',
    component: extensionDetails,
  },
  {
    path: '/agreeMent',
    name: 'AgreeMent',
    component: agreeMent
  },
 

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
