<template>
  <div class="content">
    <div class="headers">
        <div style="display: flex;align-items: center;">
          <img src="@/assets/leftBtn.png" alt="" @click="go" class="headers-img">
          <div class="headers-name" >Detalles del préstamo</div>
        </div>
      <problemFeedback :title="title"></problemFeedback>
    </div>
    <div class="mian">
       <div class="title-box">
        <div class="title-top">
          <div style="display: flex;align-items: center;">
            <van-skeleton title avatar  :loading="loading">
           <img :src="img" alt="" class="title-left">
          </van-skeleton>
          <div style="font-size: 0.48rem;color: #212121;margin-bottom: 0.1067rem;">{{ productName }}</div>
      
          </div>
           <div>
            <div class="right-bottom" v-if="['200'].includes(status)">Reembolsado</div>
              <div class="right-bottom" v-if="['90','100'].includes(status)">En revision</div>
              <div class="right-bottom1" v-if="['150'].includes(status)">Depositando</div>
           </div>
        </div>
        <div class="title-center">
          <div class="center-left">
            <div style="font-size:0.4267rem;font-weight: bold;color: rgba(0,0,0,0.85);text-align: initial;margin-bottom: 0.1333rem;">{{ loanAmount }}</div>
            <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
              Monto del préstamo($)
            </div>
          
          </div>
          <div class="center-right">
            <div style="font-size:0.4267rem;font-weight: bold;color: rgba(0,0,0,0.85);text-align: initial;margin-bottom: 0.1333rem;">{{term}}</div>
          <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
            Plazo del prestamo(Días)</div>
       
      </div>
        </div>
        <div class="title-bottom"></div>
      
       </div>
       <div class="Details">
        <div class="Details-title">Detalles del préstamo</div>
       </div>
       <div class="content-box">
        <div class="Details-num" v-for="(item,index) in list" :key="index" >
                <div class="num-left">{{ item.aniejado }}</div>
                <div class="num-right" v-html="item.salegarais"></div>
          </div>
        <!-- <div class="Details-loan" @click="privacidad('Acuerdo de prestamo')">
          Ver contrato de préstamo>
        </div> -->
      
       </div>
      
       
      
       <agreeMent class="agreeMent"></agreeMent>
    </div>
   
    <repaymentVoucher ref="repaymentVoucher"></repaymentVoucher>
  </div>
</template>

<script>
import { Toast } from 'vant';
import problemFeedback from '@/components/problemFeedback.vue'
import repaymentVoucher from '@/components/repaymentVoucher.vue'
import agreeMent from '@/components/agreeMent.vue'
import { base64ToImage } from '@/utils/baseImg'
import {getOrderDetails} from '@/views/order/lib'
export default {
name:'orDer',
components:{agreeMent,problemFeedback,repaymentVoucher},

data(){
 return{
  title:"orDer",
    list:[],
    productName:'',
    loanAmount:'',
    term:'',
    img:"",
    orderNo:localStorage.getItem('orderNo')||'',
    status:null,
    loading:true,
 }
},
computed: {
  },

methods: {
  privacidad (name) {
      this.$router.push({ name: 'AgreeMent', query: { name } })
    },

 async getOrderDetails(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    const res=await getOrderDetails({
      orderNo:this.orderNo, //170状态 放款成功
      pageType:'1' //170状态 放款成功
    })
    console.log(res,'订单详情');
  if(res.conearia==='200'){
    loadingInstance.clear()
    this.loading=false,
    this.list=res.tallecieras.calceatense
    this.productName=res.tallecieras.laboreos
    this.loanAmount=res.tallecieras.descayesen
    this.term=res.tallecieras.embebieres
    this.img = base64ToImage(`${'data:image/png;base64,' + res.tallecieras.nornordeste}`)
    this.status=res.tallecieras.clamides
    if (this.status === '90') {
      this.type = 'UNDER_REVIEW'
    }else if (this.status === '100') {
      this.type = 'LENDING'
    }  else if (this.status === '200') {
      this.type = 'REPAID'
    }
    console.log(this.status,'status');
    this.$managementEvents({ pageName: 'esgrafiarian', action: 'cortezo', extInfo: {  type: this.type, loanOrderId: this.orderNo } })
}else{
  loadingInstance.clear()
}

  },
  go(){
    this.$javaGet('returnH5')
  },
  // 借款协议
  loan(name){
    this.$router.push({ name: 'AgreeMent', query: { name } })
  }, 
 
  

  },
  mounted(){
    this.getOrderDetails()
  }
}
</script>

<style lang="less" scoped>

.content{
  // width: 100%;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .headers{
    width: 100%;
    height: 2.0533rem;
    padding: 0.9867rem 0.5333rem 0 0.5333rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
      position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
      background: #FEFFFA;
    .icon{
      font-size: 0.5rem;
      font-weight: 600;
      color: #000000;
    }
    .headers-name{
      font-size: 0.4267rem;
      color: #000000;
      font-weight: bold;
    }
    .headers-img{
      width: 0.8533rem;
      height: 0.8533rem;
      margin-right: 0.2667rem;
    }
  }
  .mian{
    margin-top:2.0533rem;
    width: 100%;
    height: 100%;
    overflow:auto;
    padding: 0 0.5333rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FEFFFA;
    .agreeMent{
      // position: absolute;
      // bottom: 0.8rem;
      margin: 0.2667rem 0 0.8rem 0;
    }
    .title-box{
      width: 100%;
     margin-top: 0.2933rem;
      height: auto;
      border-radius: 0.64rem;
      border: 0.0267rem solid #000000;
      // border:0.0267rem solid #EAEAEA;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 0.3733rem 0 0 0;
       .title-top{
       width: 100%;
       display: flex;
       align-items: center;
       padding: 0 0.3733rem;
       justify-content: space-between;
        .title-left{
          width: 1.12rem;
          height: 1.12rem;
          margin-right: 0.4rem;
          border-radius:0.32rem;
          border: 0.0267rem solid #000000;
        }
        .title-right{
          display: flex;
          flex-direction: column;
          text-align: initial;
         
        }
        .right-bottom{
            width: auto;
            height: 0.7467rem;
            display:flex;
            justify-content: center;
            padding:0 0.32rem ;
            align-items: center;
            font-weight: 500;
            background: #000000;
            border-radius:0.3733rem;
            color: #FFFFFF;
            font-size: 0.32rem;
          }
          .right-bottom1{
            width: auto;
            height: 0.7467rem;
            display:flex;
            padding:0 0.32rem ;
            align-items: center;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background: #000000;
            border-radius:0.32rem;
            color: #FFFFFF;
            font-size: 0.32rem;
          }
       }
       .title-center{
        margin-top: 0.3733rem;
        margin-bottom: 0.0267rem;
        border-radius: 0 0 0.64rem 0.64rem;
        border-top: 0.0267rem solid #000000;
        display: flex;
        background: #F9FFDE;
        justify-content: space-between;
        .center-left{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-left: 0.8rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
         
        }
        .center-right{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-right: 0.8rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
        }
       }
      
    }
    .Details{
      width: 100%;
      // margin-top:0.2933rem;
      // padding:0 0.2667rem;
      .Details-title{
        text-align:initial;
        font-weight: bold;
        font-size: 0.48rem;
        color: #3A3A47;
        margin-bottom: 0.24rem;
        margin-top: 0.6733rem;
      }
    }
    .content-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0.3733rem 0.64rem 0.8267rem 0.64rem;
      border: 0.0267rem solid #000000;
      border-radius:0.4267rem;
      background: #FFFFFF;
      margin-bottom: 0.64rem;
// .Details-num:last-child {
//      padding-bottom:none !important; /* 最后一个元素没有下横线 */
// }
      .Details-loan{
        color: #666666;
        font-size: 0.32rem;
        margin:0.2667rem 0 0.8rem 0;
      }
      .Details-num{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:0.4033rem;
     
      // padding-bottom:0.4533rem;
      // border-bottom:0.0267rem solid #ddd; /* 所有元素都有下横线 */
      .num-left{
        font-weight: 500;
         font-family: Aeonik, Aeonik;
        font-size: 0.3733rem;
        color: #000000;
      }
      .num-right{
        font-weight: bold;
        font-size: 0.3733rem;
        color: #000000;
      }
    }
  
    }

   
  }

 

}

</style>