import axios from 'axios'
// import Request, { API } from '@/http/index.js'
import { Toast } from 'vant'
// import router from '@/router/'

// function errorCatch (data) {
//   if (/debug/.test(data.config.url)) return false
//   const content = `${data.config.url}:${data.data.message} }`
//   Request.getData(API.errorStatistics + `?anakSemang=${content}&loyang='全局'`)
// }

const Axios = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  baseURL:'/xins',
  timeout: 0
})

Axios.interceptors.request.use(config => {
  config.headers['x-auth-token'] = localStorage.getItem('token')
  config.headers['x-version'] = localStorage.getItem('x-version')
  config.headers.versionNumber = '1.0.0'
  config.headers['x-package-name'] = localStorage.getItem('x-package-name')

  return config
}, error => {
  return Promise.reject(error)
})

Axios.interceptors.response.use(response => {
  const code = response.data.conearia
  if (code === '-1') {
    // localStorage.removeItem('token')
    // localStorage.removeItem('userId')
    Toast(response.data.esfacelaseis)
    // router.replace('/login')
  }
  if (code === '99') {
    Toast(response.data.esfacelaseis)
    // errorCatch(response)
  }
  // if (code !== '200') {
  //   Toast('Red no disponible')
  //   // errorCatch(response)
  // }
  if (code === '500') {
    Toast('Red ocupada')
    // response.config.url.replace(process.env.VUE_APP_BASE_URL + '/', '')
    // errorCatch(response)
  }
  return response.data
}, error => {
  Toast('Oops, connecting to the server failed')
  return Promise.reject(error)
})
export default Axios
