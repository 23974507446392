<template>
  <div>
    <van-overlay :show="show" @click="show = false">
  <div class="wrapper" @click.stop>
    <div class="block" >
      <div class="content-box">
        <div class="content-title">
         <img :src="img" alt="" style="width: 0.8rem;height: 0.8rem;margin-right: 0.2133rem;">
         <span style="font-size: 0.4267rem;color: #3A3A47;">{{ productName }}</span>
        </div>
        <div class="content-name">
          Transaction successfully completed！
        </div>
        <div class="content-time">{{ time }}</div>
        <div class="line-box">
          <div class="sbox-left"></div>
          <div class="line"></div>
          <div class="sbox-right"></div>
        </div>
        <div class="amount-name">Amount:</div>
        <div class="amount-num">₱{{ amount }}</div>
        <div class="pay-title">Payed by</div>
        <div class="pay-name">
            <div class="paybox">
               <div style="font-size:0.30rem;color: #3A3A47;">Bank</div>
               <div style="font-size:0.30rem;color: #3A3A47;">{{ bankName  }}</div>
            </div>
            <div  class="paybox">
              <div style="font-size:0.28rem;color: #3A3A47;">Number of bank card </div>
               <div style="font-size:0.28rem;color: #3A3A47;">{{ bankCard  }}</div>
            </div>
            <div  class="paybox">
              <div style="font-size:0.28rem;color: #3A3A47;">Name and surname  </div>
               <div style="font-size:0.28rem;color: #3A3A47;">{{ realName  }}</div>
            </div>
        </div>
        <div class="ficiary-title">Beneficiary</div>
        <div class="pay-name">
            <div class="paybox">
               <div style="font-size:0.28rem;color: #3A3A47;">app name </div>
               <div style="font-size:0.28rem;color: #3A3A47;"></div>
            </div>
            <div  class="paybox">
              <div style="font-size:0.28rem;color: #3A3A47;">No.order</div>
               <div style="font-size:0.28rem;color: #3A3A47;">{{ orderNo }}</div>
            </div>
        </div>
        <div class="line-box">
          <div class="sbox-left"></div>
          <div class="line"></div>
          <div class="sbox-right"></div>
        </div>
        <div class="save">Save to gallery></div>
      </div>
   <img  src="@/assets/loanOff.png" alt=""  @click="close" class="close-icon"/>
    </div>
  </div>

</van-overlay>
  </div>
</template>

<script>

import { getLoanCertificate } from '@/views/order/lib'
import { formatTimestamp } from '@/utils/time'
import { dataMoney } from '@/utils/money'
export default {
  data () {
    return {
      show: false,
      title: '',
      amount:'',
      appName:'',
      bankCard:'',
      bankName:'',
      orderNo:'',
      time:'',
      realName :'',
      productName:"",
      img:'',


    }
  },
  computed: {

  },
  methods: {
    formatTimestamp,
    dataMoney,
    close () {
      this.show = false
    },
   async getLoanCertificate(orderNo){
      const res=await getLoanCertificate({
        orderNo:orderNo
      })
      console.log(res);
      if(res.code==='200'){
        this.amount=dataMoney(res.data.amount)
        this.appName=res.data.appName
        this.bankCard=res.data.bankCard
        this.bankName=res.data.bankName
        this.orderNo=res.data.orderNo
        this.realName =res.data.realName 
        this.time=formatTimestamp(res.data.time)
      }
    },
    async onSubmit(){
   
    },
    input(){

    },
    async getCustomerPhone () {
   
    }
  },
  created () {
  },
  mounted () {
  }

}
</script>

<style lang="less" scoped>

.van-cell{
  background: #FFFFFF;
}
::v-deep .van-cell::after{
  position:static;
}
::v-deep .van-field__control::-webkit-input-placeholder{
  font-size: 0.3733rem;
  color: #999999; /* 设置占位符文本的颜色 */
}
::v-deep .van-field__control {
  color: #000;
  // font-weight:700;
  height: 2.2667rem !important;
}
.from{
  width: 100%;
 display: flex;
 flex-direction: column;
align-items: center;
}
.field{
  height: 3.1467rem;
  border-radius: 0.2667rem ;
  border: 0.0267rem solid #999999;
  margin: 0.2667rem 0;

}
 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    display: flex;
    position: relative;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // margin-top: 3.9467rem;
    width: 8.5333rem;
    height:10rem;
    background: #FFFFFF;
    border-radius:0.1333rem;
    position: relative;
    .titl-bottom{
      width: 100%;
      font-weight: bold;
      text-align: initial;
      margin-top: 0.4533rem;
      font-size:0.3733rem;
      color: #3A3A47;
    }
    .bottom-btn{
      margin-top: 0.32rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-left{
        display: flex;
        font-size: 0.4267rem;
        font-weight: bold;
        color: #3A3A47;
        width: 4.6rem;
        height: 1.3333rem;
        background: #FFFFFF;
        border:0.0267rem solid #999999;
        border-radius: 0.1333rem;
        padding: 0.4533rem 0 0.3733rem 0.32rem;
      }
      .btn-right{
      width:2.6667rem;
      height:1.3333rem;
      font-weight: bold;
      background: #339E6B;
      border-radius: 0.1333rem;
      display: flex;
      font-size:0.4267rem;
      font-weight: bold;
      color: #E0E0E0;
      justify-content: center;
      align-items: center;
      }
     }
    
    .content-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      // justify-content: center;
     .content-title{
     width: 100%;
     height: 0.8rem;
     display: flex;
     align-items: center;
     text-align: initial;
     margin: 0.3733rem 0 0.4067rem 0.7733rem;
     }
     .content-name{
      width: 100%;
      text-align: initial;
      font-size: 0.3733rem;
      margin-left: 0.72rem;
      color: #339E6B;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
      margin-bottom:0.2133rem;
     }
     .content-time{
      font-size:0.2667rem;
      color: #666666;
     }
     .line-box{
      display: flex;
      align-items: center;
      margin: 0.4rem 0;
      .sbox-left{
        border-top: 0.1333rem solid transparent;
          // border-right:  0.2333rem solid transparent;
          border-bottom:  0.1333rem solid transparent;
          border-left:  0.2333rem solid #D9D9D9;
      }
      .line{
        width: 100%;
        height: 0;
        border: 0.0267rem dashed #D9D9D9;
      }
      .sbox-right{
        border-top: 0.1333rem solid transparent;
          border-right:  0.2333rem solid #D9D9D9;
          border-bottom:  0.1333rem solid transparent;
          // border-left:  0.2333rem solid transparent;
      }
     }
     .save{
      font-size: 0.32rem;
      color: #339E6B;
      margin-bottom: 0.5067rem;
     }
     .amount-name{
      text-align: initial;
      font-size:0.32rem;
      color: #666666;
      margin-left: 0.7733rem;
      margin-bottom: 0.2667rem;
     }
     .amount-num{
      text-align: initial;
      font-size:0.5333rem;
      color: #3A3A47;
      font-weight: bold;
      margin-left: 0.7733rem;
      margin-bottom: 0.88rem;
     }
    .pay-title{
      text-align: initial;
      font-size: 0.32rem;
      color: #666666;
      margin-left: 0.7733rem;
      margin-bottom: 0.1867rem;
    }
    .pay-name{
      display: flex;
      flex-direction: column;
      margin: 0 0.64rem 0 0.7467rem;
      .paybox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.2133rem;
      }
    }
    .ficiary-title{
      text-align: initial;
      font-size: 0.32rem;
      color: #666666;
      margin-left: 0.7733rem;
      margin-bottom: 0.1867rem;
      margin-top: 0.2667rem;
    }




    }
   
    // .btn-top:disabled{
    //   opacity: 0.9; /* 设置禁用状态下的透明度 */
    //   background:#646b73;
    //   color: #000;
    // }
  }
  .close-icon {
    width: 0.8rem;
    height: 0.8rem;
    // margin-top: 1.4133rem;
    border: none;
    top: -1.1467rem;
    right:0;
    position: absolute;
  }

</style>
