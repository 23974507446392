export default {
  managementEvents:'/flexibilizara/anolariais/ecuatoriana/',//打点
  order:{
    dataDetails:'/flexibilizara/pirasen/tarificaciones', //订单详情
    LoanCertificate:'/api/order/loan-certificate',//放款凭证
    AutoApply:'/api/order/show-auto-apply', //是否展示自动复借按钮
    SettledAutoApply:'/api/order/settled-auto-apply', //是否自动复借
    autoReloan:'/flexibilizara/pirasen/hoyancos', //是否自动复借
    RepaymentDetail:'/flexibilizara/pirasen/ensayariamos', //还款详情
    UpOrderUtr:'/api/order/up-order-utr', //更新订单utr
    BankCard:'/api/auth/bank-card-list-base64', //获取银行卡列表
    RepaymentChannel:'/api/order/repayment-channel', //还款方式列表
    CalculateDelay:'/flexibilizara/carboneasemos/enhenamos'//展期还款详情
  },
  customer:{
    CustomerPhone:'/flexibilizara/anolariais/tilosa',//获取客服电话
    Complaint:'/flexibilizara/descontentad/apoquinad'//获取客服电话
  }
}