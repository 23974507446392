<template>
  <div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="top">
               
            <div class="top-title">Préstamo express</div>
            <div class="tips">¿Confirma que desea renunciar al servicio de</div>
            <div class="tips"> préstamo ultrarrápido que incluye revisión</div>
            <div class="tips"> prioritaria y desembolso rápido?</div>
            <van-button type="primary" class="bottom-btn"  @click="automaticLoan">Considerar</van-button>
            <van-button  class="bottom-btn1"   @click="RepaymentOnly">Renunciar con determinación</van-button>
          
          </div>
          <!-- <img  src="@/assets/offbtn.png" alt="" @click="entiendo" class="close-icon" /> -->
        </div>
        
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {getautoReloan} from '@/views/order/lib'
export default {
  data () {
    return {
      show: false,
      showBtn:null,
      data:null,
      url:null,
      loadingBtn:null,
      orderNo:null,
      settledAutoApplyAmount:null,
    }
  },
  methods: {
    entiendo () {
      this.show = false
      
    },
        // handleOverlayClick(event){
    // // console.log(event,'event');
    // if (event.target._prevClass === 'wrapper') {
    //     this.show = false; // 点击 van-overlay 盒子时触发关闭操作
    //   }
    // //  }
    // },

   

async automaticLoan(){
  if (this.loadingBtn) return
      this.loadingBtn = true
      this.$managementEvents({ pageName: 'carraquease', action: 'descerebrasen', extInfo: { loanOrderId: this.orderNo }  })
     const res =await getautoReloan({
      orderNo:this.orderNo, //170状态 放款成功
      open:true, //170状态 放款成功
    })
 
     
    if(res.conearia==='200'){
      this.show = false
      this.$emit('change')
    }
    setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
           
  },
 async RepaymentOnly(){
    if (this.loadingBtn) return
      this.loadingBtn = true
      this.$managementEvents({ pageName: 'carraquease', action: 'narraremos', extInfo: { loanOrderId: this.orderNo }  })
     const res =await getautoReloan({
      orderNo:this.orderNo, //170状态 放款成功
      open:false, //170状态 放款成功
    })

    if(res.conearia==='200'){
      this.show = false
      this.$emit('changechecked')
    }
    setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
  }


  },
  mounted(){
    // this.getBankCard()
  }

}
</script>

<style lang="less" scoped>
.van-overlay {
  z-index: 99999;
}
.wrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
 
  .block {
    width: 8.72rem;
    height: auto;
    position: relative;
    background: #F6F7F5;
    display: flex;
    flex-direction: column;
    padding: 0.64rem 0.8rem;
    border-radius: 1.0667rem;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-title {
        color: #000000;
        text-align: left;
        width: 100%;
        font-size: 0.5333rem;
        font-weight: 700;
      
        margin-bottom: 0.4rem;
      }
      .tips{
        text-align: left;
        width: 100%;
        font-size: 0.34rem;
        color: #666666;
      }
      .loan-title{
        margin-top: 0.8267rem;
        color: #000000;
        font-size: 0.4267rem;
      }
      .loannum{
        margin-bottom: 0.6133rem;
        margin-top: 0.1333rem;
        font-size: 0.7467rem;
        font-weight: 700;
        color: #000000;

      }
      .account-list{
        width: 100%;
        margin-bottom: 0.3467rem;
        text-align: initial;
        font-size: 0.3733rem;
        color: #000000;
      }
      .account-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom:0.4267rem;
        .account-left{
          font-size: 0.3733rem;
          color: #8F939B;
        }
        .account-right{
          font-size: 0.3733rem;
          color: #000000;
          font-weight:600;
        }
      }
      .bottom-tip{
        font-size:0.3467rem;
        color: #8F939B;
      }
      .bottom-btn{
        margin-top:0.5067rem;
        width:100%;
         height:1.5733rem;
         font-weight: bold;
         font-size: 0.3733rem;
         color: #000000;
         background: #D5F64F;
         border-radius:0.8rem;
         border: none;
      }
      .bottom-btn1{
        margin-top: 0.32rem;
        width:100%;
         height:1.5733rem;
         font-size: 0.3733rem;
         color: #FFFFFF;
         background: #989996;
         font-weight: bold;
         border-radius: 0.8rem;
        
      }

    }

  }



  .close-icon{
    width: 0.32rem;
    height: 0.32rem;
    // margin-top: 1.4133rem;
    border: none;
    top: 0.5333rem;
    right:0.5333rem;
    position: absolute;
  }
}</style>
