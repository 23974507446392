import Request, { API } from '@/http/'
export function managementEvents (data) {
  Request.getData(API.managementEvents +`${data.pageName}/${data.action}`, {
    emplastados: localStorage.getItem('adid') || '',
    acusado: localStorage.getItem('userRandomKey') || '',
    aprehendiereis: data.extInfo ? JSON.stringify(data.extInfo) : undefined,
  })
}

export const $managementEvents = managementEvents
export default {
  install (Vue, options) {
    console.log(options);
    Vue.prototype.$managementEvents = managementEvents
  }
}
