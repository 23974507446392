<template>
  <div class="content">
    <div class="headers">
      <div style="display: flex;align-items: center;">
        <img src="@/assets/leftBtn.png" alt="" class="headers-img" @click="go">
        <div class="headers-name">Detalles del préstamo</div>
      </div>
     
      <problemFeedback :title="title"></problemFeedback>
    </div>
    <div class="mian">
       <div class="title-box">
        <div class="title-top">
          <div style="display: flex;align-items: center;">
            <van-skeleton title avatar  :loading="loading">
           <img :src="img" alt="" class="title-left">
          </van-skeleton>
          <div style="font-size: 0.3733rem;color: #000000;margin-bottom: 0.1067rem;font-weight: bold; white-space: nowrap;overflow: hidden; text-overflow: ellipsis;width: 2.333rem;">{{productName}}</div>
          </div>
         
         <div>
          <div class="title-right">
             
             <div class="right-bottom" v-if="['180'].includes(status)">Reembolso atrasado</div>
             <div class="right-bottom1" v-if="['170'].includes(status)">Reembolso pendiente</div>
          </div>
         </div>
        </div>
        <div class="title-center">
          <div class="center-left">
            <div style="font-size:0.4267rem;font-weight: bold;color: rgba(0,0,0,0.85);text-align: initial;margin-bottom: 0.1333rem;">{{ loanAmount }}</div>
            <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
              Monto del préstamo($)
            </div>
          
          </div>
          <div class="center-right">
            <div style="font-size:0.4267rem;font-weight: bold;color: rgba(0,0,0,0.85);text-align: initial;margin-bottom: 0.1333rem;">{{term}}</div>
          <div style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
            Plazo del prestamo(Días)</div>
       
      </div>
        </div>
        <div class="title-bottom"></div>
      
       </div>

      
       <van-button size="large" class="btn-top" v-if="delayStatus===1" @click="extensionDetails">
        Voy a pedir prórroga
        </van-button>
        <van-button size="large" class="btn-top1" @click="normalRepayment" >
          Ir a reembolsar
        </van-button>
        <div class="period" v-if="repaymentPlans?.length > 0">
           <div class="period-top">
              <div class="period-left">Número de plazos</div>
              <div class="period-right" @click="showperiod">{{ repaymentPlans?.length }} Plazos 
                <div style="display: flex;justify-content: center;align-items: center;width: 0.6933rem;height: 0.6933rem;margin-left: 0.2rem;" >
                  <img src="@/assets/rightclick.png" alt=""  style="width:0.5333rem;height: 0.5333rem" v-if="!showperiodre">
                  <img src="@/assets/bottomclick.png" alt=""  style="width:0.5333rem;height:0.5333rem;" v-else>
                </div>
               
                
              </div>
           </div>
           <div>
            <van-steps direction="vertical" :active="3" active-color="#fff" v-if="showperiodre" >
            

                <van-step v-for="(itme,index) in repaymentPlans" :key="index">
                  <template #finish-icon>
                    <div class="flag-icon"></div>
                  </template>
                  <div class="step-box">
                    <div class="step-top">
                      <div class="step-left" :class="{'step-bgColor': ['180'].includes(status)&&index === 0}">{{itme.izamos}} Plazo<span v-if="index !== 0">s</span> </div>
                      <div class="step-right">{{itme.emancipad}}</div>
                    </div>
                    <div class="step-bottom">
                      <div class="step-left">Importe a pagar : </div>
                      <div class="step-right" :class="{'underline': index !== 0}"> {{itme.huidos}}</div>
                    </div>
                    <div class="step-bottom" v-if="['180'].includes(status)&&index === 0">
                      <div class="step-left">Intereses de penalización: </div>
                      <div class="step-expiryAmount">{{itme.alcoholizaran}}</div>
                    </div>
                  </div>
                
                </van-step>
         </van-steps>
           </div>
        </div>
        <div class="periodre" v-if="repaymentPlans?.length > 0">
           <div class="period-tip">
              <div class="period-img"></div>
              <div class="period-rightTIP">Oferta de hoy <span style="color: #000000;">solo liquida la primera fase </span>ya<br> puede liquidar el prestamo total.
              </div>
           </div>

        </div>
        <div style="display: flex;margin-bottom: 0.9333rem;" v-if="showAutoReloanSwitch && hadOverdue">
             <img src="@/assets/tipre.png" alt="" style="width:0.3677rem;height: 0.3677rem; margin-right: 0.2667rem;">
            <div style="display: flex;flex-direction: column;">
              <div class="tiptext">Actualmente existen pedidos vencidos, por lo que no </div>
              <div class="tiptext">puede disfrutar del servicio de préstamo express</div>
            </div>
       </div>
       <!-- -->
        <div style="display: flex;align-items: center;margin-bottom: 0.9333rem;"  v-if="showAutoReloanSwitch && !hadOverdue">
        <van-checkbox v-model="checked" >
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" @click="showLOAN" />
                </template>
            </van-checkbox>
            <div class="checktext">{{ AutoReloanSwitchName  }}</div>
       </div>
        <div class="Details" >
        <div class="Details-title">Detalles del préstamo</div>
       </div>
       <div class="content-box">
        <div class="Details-num"  v-if="repaymentTotalAmount||repayAmountMultiPeriods">
              <div class="num-left">Monto total reembolsable</div>
              <div class="num-right">
                <div style="height: 0.8rem;padding: 0.1667rem;">
                  <span v-if="repaymentTotalAmount">${{ repaymentTotalAmount }}</span> <br><span style="text-decoration: line-through;font-weight: normal;font-size:0.3067rem " v-if="repayAmountMultiPeriods">${{ repayAmountMultiPeriods }}</span>
              </div>
            
            </div>
       </div>
        <div class="Details-num"  >
              <div class="num-left">Fecha de vencimiento</div>
              <div class="num-right">{{ expiryTime }}</div>
       </div>
        
        <div class="Details-num" v-for="(item,index) in list" :key="index" >
              <div class="num-left">{{ item.aniejado }}</div>
              <div class="num-right" v-html="item.salegarais" :class="{'InteresesColor': item.aniejado==='Intereses de penalización'}"></div>
       </div>
      
       </div>
 
       
      
        <agreeMent style="margin-top:0.4rem;margin-bottom: 0.8rem;"></agreeMent>
    </div>
  
    <repaymentVoucher ref="repaymentVoucher"></repaymentVoucher>
    <loanDlog ref="loanDlog" @changechecked="changechecked" @change="getOrderDetails"></loanDlog>
  </div>
</template>

<script>
import { Toast } from 'vant';
import problemFeedback from '@/components/problemFeedback.vue'
import repaymentVoucher from '@/components/repaymentVoucher.vue'
import loanDlog from '@/components/loanDlog.vue'
import agreeMent from '@/components/agreeMent.vue'
import { base64ToImage } from '@/utils/baseImg'
import {money} from '@/utils/money'
import {getOrderDetails,getautoReloan} from '@/views/order/lib'
import { mapMutations } from 'vuex'
export default {
name:'orDer',
components:{agreeMent,problemFeedback,repaymentVoucher,loanDlog},

data(){
 return{
  title:"orDer",
    list:[],
    productName:'',
    img:"",
    checked:false,
    loanAmount:"",
    expiryTime:"",
    term:"",
    repaymentPlans:[],
    repaymentTotalAmount:"",
    repayAmountMultiPeriods:"",
    showperiodre:false,
    type:"",
    showAutoReloanSwitch:"",
    AutoReloanSwitchName :"",
    hadOverdue:"",
    delayStatus:'',
    orderNo:localStorage.getItem('orderNo')||'',
    status:null,
    loading:true,
    activeIcon: require('@/assets/on.png'),
    inactiveIcon: require('@/assets/reoff.png'),
 }
},
computed: {
  },

methods: {
  money,
  ...mapMutations('extensionDetails', ['changeOrderDetails']),
  getStepIcon(index) {
      const icons = [
        require('@/assets/rightclick.png'),
        require('@/assets/rightclick.png'),
        require('@/assets/rightclick.png'),
      ];
      return icons[index];
    },
  // 自动复借
  changechecked(){
    this.checked=false
  },
 async showLOAN(){
  // this.$refs['loanDlog'].show=true
    if(!this.checked){
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      this.$managementEvents({ pageName: 'carraquease', action: 'malhechor', extInfo: {  type: 'Close', loanOrderId: this.orderNo }  })
      const res =await getautoReloan({
      orderNo:this.orderNo, //170状态 放款成功
      open:true, //170状态 放款成功
    })

    if(res.desacaloraren==='200'){
      this.checked=true
      loadingInstance.clear()
    }else{
      loadingInstance.clear()
    }

    }else{
      this.checked=true
      this.$managementEvents({ pageName: 'carraquease', action: 'cortezo', extInfo:'' })
      this.$refs['loanDlog'].show=true
      this.$managementEvents({ pageName: 'carraquease', action: 'malhechor', extInfo: {  type: 'Open', loanOrderId: this.orderNo }  })
      this.$refs['loanDlog'].orderNo=this.orderNo
    }
   

  },
 async getOrderDetails(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    const res=await getOrderDetails({
      orderNo:this.orderNo, //170状态 放款成功
      pageType:'2' //170状态 放款成功
    })
    console.log(res,'订单详情');
  if(res.conearia==='200'){
    loadingInstance.clear()
    this.loading=false,
    this.changeOrderDetails(res.tallecieras)
    this.list=res.tallecieras.calceatense
    this.productName=res.tallecieras.laboreos
    this.loanAmount=res.tallecieras.descayesen
    this.showAutoReloanSwitch=res.tallecieras.pasividad
    this.hadOverdue=res.tallecieras.enjarcie
    this.checked =res.tallecieras.aplantillaremos 
    // this.hadOverdue=false
    // this.showAutoReloanSwitch=true
   
    this.AutoReloanSwitchName =res.tallecieras.deslendrabais 
    this.repaymentPlans =res.tallecieras.despercudidos 
    this.repaymentTotalAmount =res.tallecieras.diferida?money(res.tallecieras.diferida):""
    this.repayAmountMultiPeriods =res.tallecieras.anteveis ?money(res.tallecieras.anteveis):""

    this.expiryTime=res.tallecieras.abstractivo
    this.delayStatus=res.tallecieras.guimbaletes
    this.term=res.tallecieras.embebieres
    this.img = base64ToImage(`${'data:image/png;base64,' + res.tallecieras.nornordeste}`)
    this.status=res.tallecieras.clamides
    if (this.status === '170') {
      this.type = 'TO_REPAID'
    }else if (this.status === '180') {
      this.type = 'OVERDUE'
    }
    this.$managementEvents({ pageName: 'esgrafiarian', action: 'cortezo', extInfo: {  type: this.type, loanOrderId: this.orderNo } })
}else{
  loadingInstance.clear()
}

  },
  go(){
    this.$javaGet('returnH5')
  },
  showperiod(){
    this.showperiodre=!this.showperiodre

  },
  // 借款协议
  loan(name){
    this.$router.push({ name: 'AgreeMent', query: { name } })
  }, 
  contrato(){
    
  },
  goFAQ(){
    const packageName = localStorage.getItem('x-package-name')
      const token = localStorage.getItem('token')
    window.location.href = `http://47.117.39.82/app-loanmarket-h5-faq/#/FAQ?token=${token}&packageName=${packageName}`
  },
   //  展期页面
   extensionDetails () {
      this.$router.replace({ name: 'extensionDetails'})
    },
    // 正常还款
    normalRepayment () {
      this.$router.replace({ name: 'normalRepayment' })
    }
 
  

  },
  mounted(){
    this.getOrderDetails()
  }
}
</script>

<style lang="less" scoped>
.img-icon{
  width:0.48rem;
  height:0.48rem;
  margin-right: 0.2667rem;

}
.InteresesColor{
  color: #F0726A !important;

}
.underline{
  text-decoration: line-through;
   text-decoration-thickness: 0.0533rem; /* 设置横线的粗细 */
}
/deep/ .van-step__line {
  border: 0.0267rem dashed #000000 ; /* 设置虚线样式 */
  background-color: #fff;
}
/deep/ .van-step:last-child .van-step__line  {
  border: none; /* 最后一个步骤没有连接线 */
}
/deep/ .van-step--vertical .van-step__line{
  top: 16px;
    left: -16px;
    width: 1px;
    height: 100%;
}
.van-step--vertical:not(:last-child)::after {
  border-bottom-width: 0;
}
.checktext{
  font-size: 0.32rem;
  color: #979797;
}
.tiptext{
  font-size: 0.3467rem;
  text-align: left;
  color: #FF0808;
}
.content{
  // width: 100%;
  overflow: auto;
  // height: 21.6533rem;
 
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .headers{
    width: 100%;
    height: 2.0533rem;
    // padding: 1.1733rem 0.4267rem 0 0.4267rem;
    padding: 0.9867rem 0.4267rem 0 0.5rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 0.64rem;
    background: #FEFFFA;
      position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
    .icon{
      font-size: 0.5rem;
      color: #666666;
    }
    .headers-name{
      font-size: 0.4267rem;
      color: #000000;
      font-weight: bold;
    }
    .headers-img{
      width: 0.8533rem;
      height: 0.8533rem;
      margin-right: 0.2667rem;
    }
  }
  .mian{
    margin-top:2.0533rem;
    width: 100%;
    height: calc(100vh - 2.0533rem); /* 计算剩余高度 */
    overflow:auto;
    padding: 0 0.5333rem;
    background: #FEFFFA;
    .title-box{
      width: 100%;
     margin-top: 0.2933rem;
     margin-bottom: 0.2333rem;
      height: auto;
      border-radius: 0.64rem;
      border: 0.0267rem solid #000000;
      // border:0.0267rem solid #EAEAEA;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 0.3733rem 0 0 0;
       .title-top{
        width: 100%;
       display: flex;
       align-items: center;
       padding: 0 0.2733rem;
       justify-content: space-between;
        .title-left{
          width: 1.12rem;
          height: 1.12rem;
          margin-right: 0.4rem;
          border-radius:0.32rem;
          border: 0.0267rem solid #000000;
        }
        .title-right{
          display: flex;
          flex-direction: column;
          text-align: initial;
          .right-bottom{
            width: auto;
            height: 0.7467rem;
            display:flex;
            justify-content: center;
            padding:0 0.28rem ;
            align-items: center;
            font-weight: 500;
            background: #FF0808;
            border-radius:0.3733rem;
            color: #ffffff;
            font-size: 0.32rem;
          }
          .right-bottom1{
            width: auto;
            height: 0.7467rem;
            display:flex;
            padding:0 0.28rem ;
            align-items: center;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background: #000000;
            border-radius:0.3733rem;
            color: #ffffff;
            font-size: 0.32rem;
          }
        }
       }
       .title-center{
        margin-top: 0.3733rem;
        margin-bottom: 0.0267rem;
        border-radius: 0 0 0.64rem 0.64rem;
        border-top: 0.0267rem solid #000000;
        display: flex;
        background: #F9FFDE;
        justify-content: space-between;
        .center-left{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-left: 0.8rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
         
        }
        .center-right{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          margin-right: 0.8rem;
          justify-content: center;
          flex-direction: column;
          padding: 0 0.2133rem;
          border-radius: 0.6133rem;
        }
       }
      
    }
    .Details{
      width: 100%;
      margin-top: 0.4533rem;
      // padding:0 0.2667rem;
      .Details-title{
        text-align:initial;
        font-weight: bold;
        font-size: 0.48rem;
        color: #3A3A47;
        margin-bottom: 0.4267rem;
      }
    }
    .period{
      width: 100%;
      height: auto;
      background: #FFFFFF;
      border-radius:0.4267rem;
      border: 0.0267rem solid #000000;
      margin-bottom: 0.32rem;
      padding: 0.4067rem 0.4267rem;
      .flag-icon{ 
                width:0.2933rem;          /* 圆的宽度 */
                height: 0.2933rem;         /* 圆的高度 */
                border: 0.0533rem solid #000000; /* 圆的边框颜色和宽度 */
                border-radius: 50%;    /* 使元素变成圆形 */
                background: #D5F64F; /* 背景透明，形成空心效果 */}
      .period-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
     
        .period-left{
          font-size: 0.3467rem;
          color: #000000;
          font-weight: 500;

        }
        .period-right{
          display: flex;
          align-items: center;
          font-size: 0.4267rem;
          font-weight: bold;
        }
      }
      .step-box{
        .step-top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.2933rem;
          .step-bgColor{
            background-image: url('@/assets/periodBGre.png')!important;
            background-size: 100% 100%;
            color: #FFFFFF !important;
            background-repeat:no-repeat ;
          }
          .step-left{
            background-image: url('@/assets/periodBG.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
            display: flex;
            width:1.6533rem;
            height: 0.5867rem;
            font-weight: 600;
            // padding-top: 0.1rem;
            justify-content: center;
            align-items: center;
            font-size: 0.2667rem;
            color: #333333;

          }
          .step-right{
            font-size:0.3733rem;
            color: #000000;
            font-weight: 500;

          }
        }
        .step-bottom{
          display: flex;
          .step-left{
            font-size: 0.3733rem;
            color: #999999;
          }
          .step-right{
            font-size: 0.48rem;
            font-weight: bold;
            color: #000000;

          }
          .step-expiryAmount{
            font-size: 0.3733rem;
            color: #FF0808;

          }
        }

        
      }
      .period-tip{
        display: flex;
        align-items: center;
        // justify-content: center;
        .period-img{
          width: 0.7733rem;
          margin-right: 0.1333rem;
          height: 0.8rem /* 30/37.5 */;
          background-image: url('@/assets/periodTIP.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
        }
        .period-rightTIP{
          font-size: 0.32rem;
          text-align: left;
          color: #979797;
        }
      }
    }
    .periodre{
      width: 100%;
      height: auto;
      background: #F9FFDE;
      border-radius:0.4267rem;
      margin-bottom: 0.32rem;
      padding: 0.4067rem 0.4267rem;
      .flag-icon{ 
                width:0.2933rem;          /* 圆的宽度 */
                height: 0.2933rem;         /* 圆的高度 */
                border: 0.0933rem solid #FBAE84; /* 圆的边框颜色和宽度 */
                border-radius: 50%;    /* 使元素变成圆形 */
                background-color:#FFFFFF; /* 背景透明，形成空心效果 */}
      .period-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
     
        .period-left{
          font-size: 0.3733rem;

        }
        .period-right{
          display: flex;
          align-items: center;
          font-size: 0.3733rem;
          font-weight:500;
        }
      }
      .step-box{
        .step-top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 0.2933rem;
          .step-bgColor{
            background-image: url('@/assets/periodBGre.png')!important;
            background-size: 100% 100%;
            color: #FFFFFF !important;
            background-repeat:no-repeat ;
          }
          .step-left{
            background-image: url('@/assets/periodBG.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
            display: flex;
            width:1.5467rem;
            height: 0.5333rem;
            font-weight: 600;
            // padding-top: 0.1rem;
            justify-content: center;
            align-items: center;
            font-size: 0.2667rem;
            color: #333333;

          }
          .step-right{
            font-size:0.3733rem;
            color: #000000;

          }
        }
        .step-bottom{
          display: flex;
          .step-left{
            font-size: 0.3733rem;
            color: #999999;
          }
          .step-right{
            font-size: 0.48rem;
            font-weight: 500;
            color: #000000;

          }
          .step-expiryAmount{
            font-size: 0.3733rem;
            color: #F0726A;
            

          }
        }

        
      }
      .period-tip{
        display: flex;
        align-items: center;
        // justify-content: center;
        .period-img{
          width: 0.7733rem;
          margin-right: 0.3467rem;
          height: 0.8rem /* 30/37.5 */;
            background-image: url('@/assets/periodTIP.png');
            background-size: 100% 100%;
            background-repeat:no-repeat ;
        }
        .period-rightTIP{
          font-size: 0.32rem;
          text-align: left;
          color: #999999;
        }
      }
    }
    .content-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      border: 0.0267rem solid #000000;
      padding: 0 0.64rem 0 0.64rem;
      border-radius:0.4267rem;
      background: #FFFFFF;
      margin-bottom: 0.64rem;
// .Details-num:last-child {
//   border-bottom: none; /* 最后一个元素没有下横线 */
// }
      .Details-num{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:0.4267rem;
      padding-bottom:0.4267rem;
      // border-bottom:0.0267rem solid #ddd; /* 所有元素都有下横线 */
      .num-left{
        font-weight: 400;
        font-size: 0.3733rem;
        color: #666666;
      }
      .num-right{
        display: flex;
        align-items: start;
        font-weight: 600;
        font-size: 0.3733rem;
        color: #3A3A47;
      }
    }
  
    }
    .tip-box{
      width: 100%;
      height: 1.2267rem;
      background-image: url('@/assets/tipBG.png');
      background-size:100%;
      font-size: 0.32rem;
      padding: 0.2667rem 1.0667rem 0.48rem 0.4rem;
      color: rgba(0,0,0,0.59);

      // background-position:;
    }
    .btn-top{
        width: 9.1467rem;
        height: 1.44rem;
        background: #D5F64F;
        border-radius: 0.8rem;
        color: #000000;
        font-weight: bold;
        font-size: 0.48rem;
        margin-bottom: 0.2267rem;
      }
    .btn-top1{
        width: 9.1467rem;
        height: 1.44rem;
        background: #000000;
        border-radius: 0.8rem;
        font-weight: bold;
        color: #FFFFFF;
        font-size: 0.48rem;
        margin-bottom: 0.5333rem;
      }
   
  }

 

}

</style>