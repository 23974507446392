import { render, staticRenderFns } from "./problemFeedback.vue?vue&type=template&id=409e9487&scoped=true"
import script from "./problemFeedback.vue?vue&type=script&lang=js"
export * from "./problemFeedback.vue?vue&type=script&lang=js"
import style0 from "./problemFeedback.vue?vue&type=style&index=0&id=409e9487&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "409e9487",
  null
  
)

export default component.exports