const state = {
  orderDetails: {},
  delayExpiryTime: ''
}
const getters = {
}

const mutations = {
  // 订单详情
  changeOrderDetails (state, payload) {
    state.orderDetails = payload
  },
  changeDelayExpiryTime (state, payload) {
    state.delayExpiryTime = payload
  }
}

const actions = {

}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
