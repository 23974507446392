<template>
  <div class="content">
    <div class="headers">
      <div  style="display: flex;align-items: center;">
        <img src="@/assets/leftBtn.png" alt="" class="headers-img" @click="go">
        <div class="headers-name" >Reembolso</div>
      </div>
     
      <problemFeedback :title="title"></problemFeedback>
    </div>
    <div class="mian">
       <div class="title-box">
        <div class="title-top">
          <div style="display: flex;align-items: center;">
            <img :src="img" alt="" class="title-left">
            <div style="font-size: 0.48rem;color: #212121;margin-bottom: 0.1067rem;">{{ OrderDetails.laboreos }}</div>
          </div>
          
           <div class="title-right">
              <div class="right-bottom">Reembolso</div>
           </div>
        </div>
        <div class="title-center">
            <div style="display: flex;justify-content: space-between;">
                      <div class="center-left">
                    <div style="font-size:0.4267rem;font-weight: bold;color: #17181A;text-align: initial;margin-bottom: 0.1333rem;">{{amount }}</div>
                    <div  style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
                      Monto de pago($)</div>
                  </div>
                  <div class="center-right">
                    <div style="font-size: 0.4267rem;font-weight: bold;color: #17181A;text-align: initial;margin-bottom: 0.1333rem;">{{OrderDetails.abstractivo}}</div>
                  <div  style="font-size:0.2667rem;color: #17181A;text-align: initial;display: flex;align-items: center;">
                    Fecha de vencimiento</div>
           </div>
            </div>
           <div class="center-bottom" @click="toggleShowAll">
            Detalles de la factura 
            <img src="@/assets/rightRE.png" alt="" style="width: 0.3733rem;height: 0.3733rem;" v-if="!showDetalle">
           </div>
           <div class="content-box" v-if="showDetalle">
        <div  v-for="(item,index) in OrderDetails.calceatense" :key="index" >
          <div class="Details-num" v-if="showAll || (index < visibleCount && index !== 5)">
              <div class="num-left" >{{ item.aniejado }}</div>
              <div class="num-right" v-html="item.salegarais"></div>
         </div>
         <div class="Details-num"  v-else-if="index === 5">
              <div class="num-left" >{{ item.aniejado }}</div>
              <div class="num-right" v-html="item.salegarais" ></div>
         </div>
        </div>
       
       <div class="Ocultar" @click="showDetalles" >
          <div class="Ocultar-name">Ocultar</div>
          <van-icon name="arrow-up" class="icon" />
          <!-- <van-icon name="arrow-down" class="icon" v-else /> -->
         
        </div>
       </div>
      
        </div>
        <!-- <div class="title-bottom">
        
        </div> -->
     
       </div>
       <!-- <div class="Details" >
        <div class="Details-title" @click="showDetalles">Detalles de la factura
          <img src="@/assets/bottombtn.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-left: 0.2333rem;">
        </div>
       </div> -->
     
       <!-- <img src="@/assets/ordertip.png" alt="" style="width: 9.1467rem;height: 1.92rem;margin-bottom: 0.5333rem;"> -->
       <div class="stp-Tip" v-if="!showDetalle">
        <!-- <img src="" alt=""> -->
         <div class="tips">
          <div style="font-size:0.37rem;color: #000000;text-align: left;">Nuestra aplicación solamente recibe pagos mediante</div>
          <div style="font-size:0.37rem;color: #000000;text-align: left;"><span style="color: #FF0808;">STP, Transfer o Spaynet</span>, por favor, tenga cuidado a</div>
          <div style="font-size:0.37rem;color: #000000;text-align: left;">seguridad de sus fondos</div>
         </div>
       </div>
   
       <div class="pay-style">
        <div class="ban-title" >Elección del método de pago：</div>
        <div class="paybox" v-if="!selected">
          <div class="ban-img" :class="{'img-center':  repaymentCodeList?.length===1||!OPEN_PAY_OFFLINE==='OPEN_PAY_OFFLINE'}" >
          
          <img :src="!selected?require('@/assets/UnSelectbanCos.png'):require('@/assets/SelectbanCos.png')" alt="" class="imgIcon"   @click="toggleSelected('bancos')">
          <img :src="!selected?require('@/assets/Selectban.png'):require('@/assets/UnSelectban.png')" alt="" class="imgIcon" v-if="!oxxorepaymentBank==='OXXO' && repaymentCodeList?.length===2"  @click="toggleSelected('paynet')" >
          <img :src="!selected?require('@/assets/Selectban.png'):require('@/assets/UnSelectban.png')" alt="" class="imgIconPAY"  v-if="OPEN_PAY_OFFLINE==='OPEN_PAY_OFFLINE'"  @click="toggleSelected('paynet')" >
          <img :src="!selected?require('@/assets/selectOXXO.png'):require('@/assets/UnselectOXXO.png')" alt="" class="imgIcon" v-if="oxxorepaymentBank==='OXXO' && repaymentCodeList?.length===2" @click="toggleSelected('oxxo')" >
          
        </div>
        <div class="ban-tip" > 
          <div v-if="oxxorepaymentBank==='OXXO'">
            <div style="font-size: 0.3467rem;color: #000000;text-align: initial;">Especifique el número de referencia del cajero</div>
            <div style="font-size: 0.3467rem;color: #000000;text-align: initial;">en esta etiqueta para que pueda escribir</div>
          <div style="font-size: 0.3467rem;color: #000000;text-align: initial;"> directamente en la pantalla de ventas</div>
            

          </div> 
           <div v-if="oxxorepaymentBank!=='OXXO'">
            <div style="font-size: 0.3733rem;color: #000000;text-align: initial;" >Puede efectuar el pago en efectivo en</div>
            <div style="font-size: 0.3733rem;color: #000000;text-align: initial;" > establecimientos afiliados o realizar una</div>
            <div style="font-size: 0.3733rem;color: #000000;text-align: initial;">transferencia bancaria en línea.</div>
          </div>
          
          </div>
          <!-- <div class="pay-title" v-if="oxxopaymentCode!=='No disponible'">Fecha límite de pago</div>
          <div class="pay-time" v-if="oxxopaymentCode!=='No disponible'">{{ oxxoTime}}</div> -->
          <van-image :src="openPayCode" alt="" style="width: 5.44rem;height: 1.92rem;margin-bottom:0.4267rem;" v-if="oxxopaymentCode!=='No disponible'"></van-image>
          <div class="ban-cop" >
              <div class="cop-bottom">
                  <div class="cop-left">{{ oxxopaymentCode }}</div>
                  <div class="cop-right" @click="copy"  :data-clipboard-text="oxxopaymentCode">Copy</div>
              </div>      
          </div>
      
       </div>
       <div class="ban-img " v-if="selected" :class="{'img-center':  repaymentCodeList?.length===1||!OPEN_PAY_OFFLINE==='OPEN_PAY_OFFLINE'}">
        <img :src="!selected?require('@/assets/UnSelectbanCos.png'):require('@/assets/SelectbanCos.png')" alt="" class="imgIcon"   @click="toggleSelected('bancos')">
          <img :src="!selected?require('@/assets/Selectban.png'):require('@/assets/UnSelectban.png')" alt="" class="imgIcon" v-if="!oxxorepaymentBank==='OXXO' && repaymentCodeList?.length===2"   @click="toggleSelected('paynet')" >
          <img :src="!selected?require('@/assets/Selectban.png'):require('@/assets/UnSelectban.png')" alt="" class="imgIconPAY"  v-if="OPEN_PAY_OFFLINE==='OPEN_PAY_OFFLINE'"   @click="toggleSelected('paynet')" >
          <img :src="!selected?require('@/assets/selectOXXO.png'):require('@/assets/UnselectOXXO.png')" alt="" class="imgIcon" v-if="oxxorepaymentBank==='OXXO' && repaymentCodeList?.length===2"  @click="toggleSelected('oxxo')" >
          
        </div>
        <div class="ban-tip" v-if="selected">
          <div style="font-size: 0.3733rem;color: #000000;text-align: initial;">Pague mediante transferencia bancaria a la</div>
          <div style="font-size: 0.3733rem;color: #000000;text-align: initial;"> cuenta bancaria designada</div>
          </div>
        <div class="ban-cop" v-if="selected" >
              <!-- <div class="cop-top">{{repaymentBank}}</div> -->
              <div class="cop-bottom">
                  <div class="cop-top">{{repaymentBank}}</div>
                  <div class="cop-left">{{ otherpaymentCode }}</div>
                  <div class="cop-right"  @click="copy"  :data-clipboard-text="otherpaymentCode">Copy</div>
              </div>      
        </div>
        <div class="bottom-box" v-if="selected">
          <div style="display: flex;align-items: center;">
            <div class="title-tip"></div>
            <div class="bottom-title">Como realizar el pago</div>
          </div>
         
           <div class="paymentOPM" v-if="otherRepaymentLead">
              <div v-html="otherRepaymentLead||''"  class="custom-html"></div>
          </div>
       </div>
       <div class="bottom-cos" v-if="!selected">
        <div style="display: flex;align-items: center;">
            <div class="title-tip"></div>
           <div class="bottom-top" v-if="!oxxorepaymentBank==='OXXO'">Openpay</div>
           <div class="bottom-top" v-if="oxxorepaymentBank==='OXXO'">OXXO</div>
           <div class="bottom-top" v-if="OPEN_PAY_OFFLINE==='OPEN_PAY_OFFLINE'">Openpay</div>
          </div>
           <div class="paymentOPM" v-if="oxxoRepaymentLead">
              <div v-html="oxxoRepaymentLead||''"  class="custom-html"></div>
          </div>
       </div>
       </div>
       

    
       <agreeMent class="agreeMent"></agreeMent>
    </div>
   
  </div>
</template>

<script>
import { Toast } from 'vant';
import ClipboardJS from 'clipboard'
import problemFeedback from '@/components/problemFeedback.vue'
import agreeMent from '@/components/agreeMent.vue'
import { formatTimestamptwo } from '@/utils/time'
import { base64ToImage } from '@/utils/baseImg'
import { dataMoney } from '@/utils/money'
import {getRepaymentDetail} from '@/views/order/lib'
import { mapState } from 'vuex'
export default {
name:'normalRepayment',
components:{agreeMent,problemFeedback},

data(){
 return{
  title:"normalRepayment",
    productName:'',
    img:"",
    OrderDetails:{},
    repaymentCodeList:[],
    amount:'',
    showAll:false,
    OPEN_PAY_OFFLINE:'',
    showDetalle:false,
    visibleCount: 6,
    selected: true,
    repaymentBank:'',
    otherRepaymentLead: null,
      oxxoRepaymentLead: null,
      oxxopaymentCode: null,
      oxxoTime: null,
      openPayCode: '',
      otherpaymentCode: null,
      oxxorepaymentBank: null,
      otherrepaymentBank: null,
    orderNo:localStorage.getItem('orderNo')||'',
    status:null,
    loading:true,
    opmbg:require('@/assets/stpbG.png'),
    transferbg:require('@/assets/copBG.png'),
 }
},
computed: {
  ...mapState('extensionDetails', ['orderDetails']),
  },

methods: {
 async getRepaymentDetail(){
  const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    const res=await getRepaymentDetail({
      orderNo:this.orderNo, //170状态 放款成功
      requestType: '1',
      delayTerm: '2'
    })
    console.log(res,'订单详情');
    if (res.conearia === '200') {
      loadingInstance.clear()
    this.loading=false,
        this.amount = dataMoney(res.tallecieras.propugnamos)
        // this.OrderDetailsList = res.data.extensionDetails
        // console.log(res.bayonesa, 'res.data.repaymentCodeList.length')
        if (!res.tallecieras.escandallaran) {
          this.repaymentCodeList = res.tallecieras.lotines
          res.tallecieras.lotines.forEach(item => {
            const bank = item.presidas === 'OXXO' ? 'oxxo' : 'other'
            res[bank + 'paymentCode'] = item.paquebotes
            res[bank + 'RepaymentLead'] = item.griseaste
            res[bank + 'repaymentBank'] = item.presidas
          })
          this.oxxorepaymentBank = res.oxxorepaymentBank || ''
          this.otherrepaymentBank = res.otherrepaymentBank || ''
          this.repaymentBank = res.tallecieras.lotines[0].presidas || ''
          this.otherpaymentCode = res.otherpaymentCode || ''
          this.otherRepaymentLead = res.otherRepaymentLead || ''
          this.oxxoRepaymentLead = res.oxxoRepaymentLead || ''
          this.oxxopaymentCode = res.oxxopaymentCode || ''
          this.oxxoTime = formatTimestamptwo(res.tallecieras.lotines[1]?.cuartelo)
          
        } else {
          this.OPEN_PAY_OFFLINE=res.tallecieras.escandallaran.presidas
          this.repaymentBank = res.tallecieras.lotines[0].presidas || ''
          this.repaymentCodeList = res.tallecieras.lotines
          res.tallecieras.lotines.forEach(item => {
            const bank = item.presidas === 'OXXO' ? 'oxxo' : 'other'
            res[bank + 'paymentCode'] = item.paquebotes
            res[bank + 'RepaymentLead'] = item.griseaste
            res[bank + 'repaymentBank'] = item.presidas
          })
          this.oxxorepaymentBank = res.oxxorepaymentBank || ''
          this.otherrepaymentBank = res.otherrepaymentBank || ''
          this.otherpaymentCode = res.otherpaymentCode || ''
          this.otherRepaymentLead = res.otherRepaymentLead || ''
          this.oxxopaymentCode = res.tallecieras.escandallaran.paquebotes
          this.oxxoRepaymentLead = res.tallecieras.escandallaran.griseaste
          this.oxxoTime = formatTimestamptwo(res.tallecieras.escandallaran.cuartelo)
          this.openPayCode = res.tallecieras.escandallaran.efunda
          
          
        }
      
      }
      this.$managementEvents({ pageName: 'veraneo', action: 'cortezo', extInfo: {  type: this.repaymentBank, loanOrderId: this.orderNo } })
  },
  go(){
    this.$router.replace({ name: 'overdue'})
  },
  // 借款协议
  loan(name){
    this.$router.push({ name: 'AgreeMent', query: { name } })
  },
   // 复制按钮
   copy () {
      if (this.copynum) {
        this.copynum.destroy()
      }
      this.copynum = new ClipboardJS('.cop-right')
      this.copynum.on('success', (e) => {
        Toast('éxito')
        e.clearSelection()
      })
      this.copynum.on('error', function (e) {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
      this.copynum.onClick({ currentTarget: document.querySelector('.cop-right') })
    },
  contrato(){
    
  },
  toggleShowAll(){
    this.showAll = !this.showAll
    this.showDetalle=!this.showDetalle
  },
  periodo(){
    this.$refs.extensionDetailsDlog.show = true
      this.$refs.extensionDetailsDlog.orderNo = this.orderNo
  },
  // 切换支付方式
  toggleSelected(item){
     const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
    if (item === 'bancos') {
        this.selected =true  
        this.$managementEvents({ pageName: 'optan', action: 'cortezo', extInfo: {  type: 'BANCOS', loanOrderId: this.orderNo } })
         this.$managementEvents({ pageName: 'veraneo', action: 'cortezo', extInfo: {  type: this.repaymentBank, loanOrderId: this.orderNo } })
      } else if (item === 'paynet') {
         this.$managementEvents({ pageName: 'veraneo', action: 'cortezo', extInfo: {  type: 'OPEN_PAY_OFFLINE', loanOrderId: this.orderNo } })
        this.$managementEvents({ pageName: 'optan', action: 'cortezo', extInfo: {  type: 'OpenPay', loanOrderId: this.orderNo } })
        this.selected = false
       
      }else if(item === 'oxxo'){
         this.$managementEvents({ pageName: 'veraneo', action: 'cortezo', extInfo: {  type:'OXXO', loanOrderId: this.orderNo } })
        this.$managementEvents({ pageName: 'optan', action: 'cortezo', extInfo: {  type:'OXXO', loanOrderId: this.orderNo } })
        this.selected = false
       
      }
         setTimeout(()=>{
         loadingInstance.clear()
      },500)
  },
  //显示隐藏详情
  showDetalles(){
     this.showDetalle=!this.showDetalle
  }
 
  

  },
  mounted(){
    this.$managementEvents({ pageName: 'optan', action: 'cortezo', extInfo: {  type: 'BANCOS', loanOrderId: this.orderNo } })
    this.OrderDetails = this.orderDetails
    this.DelayExpiryTime = this.delayExpiryTime
    this.img = base64ToImage(`${'data:image/png;base64,' + this.OrderDetails.nornordeste}`)
    
    this.getRepaymentDetail()
    console.log(this.repaymentBank,'this.repaymentBank');
   
  }
}
</script>

<style lang="less" scoped>
.bg{
  background:red;
}

.content{
  // width: 100%;
  overflow: auto;
  // height: 21.6533rem;
  // padding: 1.1733rem 0 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .headers{
    width: 100%;
    height: 2.0533rem;
    background: #FEFFFA;
    padding: 0.9867rem 0.4267rem 0 0.5rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.64rem;
      position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
    .icon{
      font-size: 0.5rem;
      color: #666666;
    }
    .headers-name{
      font-size: 0.4267rem;
      color: #000000;
      font-weight: bold;
    }
    .headers-img{
      width: 0.8533rem;
      height: 0.8533rem;
      margin-right: 0.2667rem;
    }
  }
  .mian{
   // margin-top: 3.2rem;
   margin-top:2.0533rem;
    width: 100%;
    height: calc(100vh - 2.0533rem); /* 计算剩余高度 */
    overflow:auto;
     padding: 0 0.4333rem;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    background: #FEFFFA;
    .title-box{
      width:100%;
     margin-top: 0.2933rem;
     margin-bottom: 0.5333rem;
      height: auto;
      border-radius: 0.64rem;
      border: 0.0267rem solid #000000;
      // border:0.0267rem solid #EAEAEA;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // align-items: center;
      padding: 0.3733rem 0 0 0;
       .title-top{
        width: 100%;
       display: flex;
       align-items: center;
       padding: 0 0.3733rem;
       justify-content: space-between;
        .title-left{
          width: 1.12rem;
          height: 1.12rem;
          margin-right: 0.4rem;
          border-radius:0.32rem;
          border: 0.0267rem solid #000000;
        }
        .title-right{
          display: flex;
          flex-direction: column;
          text-align: initial;
          .right-bottom{
            width: auto;
            height: 0.7467rem;
            display:flex;
            justify-content: center;
            padding:0 0.32rem ;
            align-items: center;
            font-weight: 500;
            background: #000000;
            border-radius:0.3733rem;
            color: #ffffff;
            font-size: 0.32rem;
          }
      
        }
       }
       .title-center{
        margin-top: 0.3733rem;
        margin-bottom: 0.0267rem;
        padding: 0 0 0.4267rem 0;
        border-radius: 0 0 0.64rem 0.64rem;
        border-top: 0.0267rem solid #000000;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        height: auto;
        background: #F9FFDE;
        justify-content: space-between;
        .center-left{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          padding-left: 0.8rem;
           padding-right: 0.2133rem;
          justify-content: center;
          flex-direction: column;
         
          border-radius: 0.6133rem;
         
        }
        .center-right{
          display: flex;
          width: 4.3733rem;
          height: 1.5rem;
          padding-right: 0.4rem;
          justify-content: center;
          flex-direction: column;
           padding-left: 0.2133rem;
          border-radius: 0.6133rem;
        }
        .center-bottom{
          display: flex;
          font-weight: bold;
          align-items: center;
        color: #000000;
        font-size: 0.3733rem;
        height: auto;
        background: #F9FFDE;
        }
        .content-box{
          width: 8.6667rem;
          display: flex;
          flex-direction: column;
          padding: 0 0.5333rem 0 0.5333rem;
          background: #F9FFDE;
      // margin-bottom: 0.64rem;
      .Ocultar{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.4267rem;
        .Ocultar-name{
          font-size: 0.3733rem;
          color: #999999;
          margin-right: 0.1333rem;
        }
        .icon{
          color: #999999;
          font-size: 0.4rem;
        }
      }
      .Details-num{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top:0.2267rem;
      padding-bottom:0.2133rem;
      // border-bottom:0.0267rem solid #ddd; /* 所有元素都有下横线 */
      .num-left{
        font-weight: 500;
        font-size: 0.3467rem;
        color: #000000;
      }
      .num-right{
        font-weight: bold;
        font-size:0.4267rem;
        color: #000000;
      }
    }
    }
       }
    }
    .stp-Tip{
     width:100%;
      display: flex;
      align-items: center;
      margin-bottom: 0.4267rem;
      .tips{
        display: flex;
        flex-direction: column;
      align-items: start;
      margin-bottom: 0.4267rem;
      }
    }
    .Details{
     width:100%;
      // padding:0 0.2667rem;
      .Details-title{
        display: flex;
        align-items: center;
        text-align:initial;
        font-weight: bold;
        font-size: 0.48rem;
        color: #3A3A47;
        margin-bottom: 0.4267rem;
      }
    }
    
    .stp-Tip{
    width:100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.2267rem;
    }
    
    .pay-style{
    width:100%;
      height: auto;
      border-radius:0.4267rem;
      padding: 0.6667rem 0.8rem;
      background: #FFFFFF;
      margin-bottom: 0.5333rem;
      border: 0.0267rem solid #000000;
      .ban-title{
      width: 7.3333rem;
      margin-bottom: 0.32rem;
      font-weight: 600;
      font-size: 0.4267rem;
      color: rgba(0,0,0,0.85);
      text-align: initial;

    }
  
    .paybox{
      width: 100%;
    // padding: 0.4267rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: #F6F6F7;
    .pay-title{
      font-size: 0.4267rem;
      font-weight: 500;
      color: rgba(0,0,0,0.85);
      margin-bottom: 0.2133rem;
    }
    .pay-time{
      font-size: 0.3733rem;
      color: #FF9C00;
      font-weight: 500;
      margin-bottom: 0.2133rem;
    }
    .ban-img{
      width: 100%;
      display: flex;
      justify-content: space-between;
      // padding: 0 0.4267rem;
      margin-bottom: 0.4267rem;
      .imgIcon{
        width: 3.52rem;
        height: 1.5467rem;
      }
        .imgIconPAY{
          margin-left: 0.2667rem;
        width: 3.52rem;
        height: 1.5467rem;
      }
     }
     .img-center{
       justify-content: center !important;
     }
     .ban-tip{
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.32rem;
     }
     .ban-cop{
      
      padding: 0.5333rem 0;
      
      // background-image: url('@/assets/copBG.png');
      background: #F9FFDE;
      width: 7.3333rem;
      margin:0 0.4267rem;
      height:1.5467rem;
      border-radius:0.4267rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

       .cop-bottom{
        display: flex;
      
        justify-content: space-between;
        align-items: center;
        position: relative;
        .cop-left{
          font-weight: 500;
          color: #000000;
          font-size: 0.4267rem;
        }
         .cop-right{
          width: 1.5467rem;
          height: 0.7467rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: #FFFFFF;
          font-size: 0.2667rem;
          background: #000000;
          border-radius: 0.6933rem;
         }
       }
      
     }
    }
    .ban-img{
      width: 100%;
      display: flex;
      justify-content: space-between;
  
      margin-bottom: 0.64rem;
      .imgIcon{
        width: 3.52rem;
        height: 1.5467rem;
      }
        .imgIconPAY{
          margin-left: 0.2667rem;
       width: 3.52rem;
        height: 1.5467rem;
      }
     }
     .img-center{
       justify-content: center !important;
     }
     .ban-tip{
      width: 100%;
    
      display: flex;
      flex-direction: column;
      margin-bottom: 0.32rem;
     }
     .ban-cop{
      // padding:0.6933rem /* 26/37.5 */ 0 0 0;
      
      background-size: 100% 100%;
      width: 7.3333rem;
      height:2.8267rem;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .cop-top{
   // width: 100%;
         position: absolute;
         top: 0.33rem;
         left: 0rem;
        text-align: initial;
        width: auto;
        font-weight: bold;
        padding: 0.2667rem;
        height: 0.5333rem;
        display: flex;
        align-items: center;
        border-radius:0.4267rem;
        background: #FF9C00;
        color: #fff;
        font-size: 0.3467rem;
        justify-content: center;
        // background-image: linear-gradient(90deg, #FCBF48 0%, #FFE6B7 100%); // 背景线性渐变
        // background-clip: text;
        // -webkit-background-clip: text; // 背景被裁减为文字的前景色
        // -webkit-text-fill-color: transparent; // 文字填充为透明，优先级比color高。
      }
       .cop-bottom{
        display: flex;
        padding: 0 0.32rem;
        justify-content: space-between;
        align-items: center;
        width: 7.3333rem;
        height: 1.8667rem;
        background: #F9FFDE;
        border-radius:0.4267rem;
        .cop-left{
          font-weight: 500;
          color: #333333;
          font-size: 0.4267rem;
        }
         .cop-right{
          width: 1.5467rem;
          height: 0.7467rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          color: #FFFFFF;
          font-size: 0.2667rem;
          background: #000000;
          border-radius: 0.6933rem;
         }
       }
      
     }
     .ban-line{
      margin: 0.5333rem 0;
      width: 7.36rem;
      height: 0;
      border: 0.0267rem solid #D9D9D9;
     }
     .bottom-box{
      width: 7.3333rem;
      height: auto;
     
      border-radius: 0.16rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.64rem;
      .title-tip{
        width: 0.08rem;
        height: 0.32rem;
        margin-right: 0.1867rem;
        background: #D5F64F;
      }
      .bottom-title{
        text-align: initial;
        font-size:0.3733rem;
        font-weight: bold;
        color: #17181A;

      }
      .paymentOPM{
        display: flex;
        margin-bottom: 0.2667rem;
        border-radius:0.5333rem;
        ::v-deep ol li {
          list-style-type: disc;
          margin-left: 0.49rem; /* 可选的左边距 */
          }
            .custom-html{
              padding:0;
              text-align: initial;
              margin-top: 0.2667rem;
              color: rgba(0,0,0,0.6);
              font-size:0.3467rem /* 14/37.5 */;
            }
         }
     }
     .bottom-cos{
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0.2133rem 0 0 0;
      .title-tip{
        width: 0.08rem;
        height: 0.32rem;
        margin-right: 0.1867rem;
        background: #D5F64F;
      }
       .bottom-top{
        text-align: initial;
        font-size:0.3733rem;
        font-weight: bold;
        color: #17181A;
       }
       .paymentOPM{
        display: flex;
        margin-bottom: 0.2667rem;
        ::v-deep ol li {
        list-style-type: disc;
        margin-left: 0.49rem; /* 可选的左边距 */
}
    .custom-html{
      padding:0;
      text-align: initial;
      margin-top: 0.2667rem;
      color: rgba(0,0,0,0.6);
      font-size:0.3467rem /* 14/37.5 */;
    }
  }
     }
    }

     
    

   
  }
  .agreeMent{
    margin-bottom: 0.6667rem;
  }


 

}

</style>