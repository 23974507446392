import Request, { API } from '@/http/index.js'

// 获取订单详情
export async function getOrderDetails (data) {
  const res = await Request.getData(API.order.dataDetails+ `?trashuman=${data.orderNo}&damnificaran=${data.pageType}`)
  return res
}
//获取客服电话
export async function getCustomerPhone () {
  const res = await Request.getData(API.customer.CustomerPhone)
  return res
}
//获取自动复借
export async function getautoReloan (data) {
  const res = await Request.getData(API.order.autoReloan+ `?trashuman=${data.orderNo}&damasquinamos=${data.open}`)
  return res
}
//用户意见反馈按钮
export async function getComplaint (data) {
  const res = await Request.getData(API.customer.Complaint,{
    pechil: data.content,
    encompadrando: data.title
  })
  return res
}
//获取放款凭证接口
export async function getLoanCertificate (data) {
  const res = await Request.getData(API.order.LoanCertificate+ `?orderNo=${data.orderNo}`)
  return res
}
//获取还款方式列表
export async function getRepaymentChannel (data) {
  const res = await Request.getData(API.order.RepaymentChannel+ `?orderNo=${data.orderNo}`)
  return res
}
//获取展期订单详情
export async function getCalculateDelay (data) {
  const res = await Request.getData(API.order.CalculateDelay,{
    trashuman: data.orderNo
  })
  return res
}
//获取还款详情
export async function getRepaymentDetail (data) {
  const res = await Request.getData(API.order.RepaymentDetail,{
    trashuman:  data.orderNo,
    enguizgue:data.requestType,
    presentanea: data.delayTerm,
  })
  return res
}