<template>
  <div>
    <van-overlay :show="show" @click="show = false">
  <div class="wrapper" @click.stop>
    <div class="block" >
      <!-- <div class="bg"></div> -->
      <div class="content">
       
        <!-- <img src="@/assets/nata.png" alt="" style="width:2.24rem;height: 2.56rem;">  -->
        <div style="font-size:0.3733rem;color: #000;font-weight: 700;display: flex;margin-bottom:0.4267rem;">Nota</div>
       <div style="font-size: 0.3733rem;color: #666666;text-align: initial;">
        Por favor, realice el pago hoy mismo para
       </div>
       <div style="font-size: 0.3733rem;color: #666666;text-align: initial;"> poder solicitar la prórroga de manera</div>
       <div style="font-size: 0.3733rem;color: #666666;text-align: initial;margin-bottom: 0.5333rem;"> exitosa.</div>
      </div>
      <van-button type="primary" size="large" class="btn-top" @click="confirmar" >Seguir</van-button>
    </div>
    <!-- <van-icon name="close"  @click="close" class="close-icon"/> -->
  </div>

</van-overlay>
  </div>
</template>

<script>

export default {
  data () {
    return {
      show: false,
      phone: '',
      delayExpiryTime: null
    }
  },
  methods: {
    close () {
      this.show = false
    },
    confirmar () {
      this.$router.replace({ name: 'extensionPayment', query: { delayExpiryTime: this.delayExpiryTime } })
    }
    // 语音验证码
    // 短信验证码

  }

}
</script>

<style lang="less" scoped>
.van-overlay{
  z-index: 999;
}
 .wrapper {
    display: flex;
    // align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;

    margin-top:6.0667rem;
    width: 8.7467rem;
    height:5.3867rem;
    padding: 0.64rem;
    background: #FFFFFF;
    
    border-radius: 0.64rem;
    position: relative;
      .bg{
        width: 8.7467rem;
        height: 2.1333rem;
      position:absolute;
      top: 0;
      border-radius: 0.4267rem 0.4267rem 0 0;
      opacity: 0.4;
        background: linear-gradient( 180deg, #BD9BC7 0%, rgba(244,244,244,0) 100%);
      }

    .content{
      font-size: 0.3733rem;
      color: #082E77;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
     
    }
    .btn-top{
      width: 7.4667rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #D5F64F;
      height: 1.3333rem;
      border-radius: 2.6667rem;
      border: none;
      font-weight: bold;
      font-size: 0.4267rem;
      color: #000000;
    }
  }
  .close-icon{
    top: 13.8667rem;
    position: absolute;
  }
  .close-icon{
    font-size: 0.6667rem;

  }

</style>
