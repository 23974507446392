<template>
  <div>
    <van-nav-bar
      :title="title"
      @click-left="goBack">
      <span class="left" slot="left">
        <van-icon name="arrow-left" @click="goBack"  class="icon"/>
      </span>
      </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'heaDer',
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      show: null
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped lang="less">
.van-nav-bar {
  height: 2.3466rem;
  padding-top: 0.6333rem;
  background-color: #FFFFFF;
 ::v-deep .van-nav-bar__title {
    color: #000;
    font-size: 0.48rem;
  }
  .left {
    display: flex;
    align-items: center;
    img {
      width: 0.2133rem;
      height: 0.3733rem;
    }
  }
}
</style>
