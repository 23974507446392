<template>
  <div class="box">
    <headers :title="title" ref="headers"></headers>
    <div style="width: 10rem; height:21.6533rem;">
      <iframe :src="url" frameborder="0" width="100%" height="100%" ></iframe>
    </div>
  </div>
</template>

<script>
import headers from './components/header.vue'
export default {
  name: 'agreeMent',
  components: { headers },

  data () {
    return {
      title: '',
      url: '',
      count: 0,
      show: null,
      time: null
    }
  },
  methods: {

  },
 
  mounted () {
    window.scrollTo(0, 0)
    const apiUrl = `${window.location.protocol}//${window.location.host}`
    console.log(apiUrl);
    console.log(this.$route.query, 'this.$route.query.name')
    switch (this.$route.query.name) {
      case 'Acuerdo de Servicio':
        // 注册协议
        console.log('进来了')
        this.title = 'Acuerdo de Servicio'
        this.url = `${apiUrl}/silladas/escavanaria`
        break
      case 'Acuerdo de Privacidad':
        // 隐私协议
        console.log('进来了')
        this.title = 'Acuerdo de Privacidad'
        this.url = `${apiUrl}/interfecto/excentricamente`
        this.$managementEvents({ pageName: 'cardamos', action: 'cortezo', extInfo: {  type: 'Privacy' } })
        break
      case 'Acuerdo de prestamo':
        // 贷款协议
        this.title = 'Acuerdo de prestamo'
        this.url = `${apiUrl}/interfecto/mancillar`
        this.$managementEvents({ pageName: 'cardamos', action: 'cortezo', extInfo: {  type: 'Loan' } })

        break
      default:
        // 执行默认操作
        this.title = 'Acuerdo de prestamo'
        // this.url = `${window.location.protocol}//${window.location.host}/contract/loan.html`
        this.$managementEvents({ pageName: 'cardamos', action: 'cortezo', extInfo: {  type: 'Loan' } })
        this.url = `${apiUrl}/interfecto/mancillar`
    }
    console.log('到这一步')
  }

}
</script>
<style lang="less" scoped>
  .box{
    min-height: 100vh;
  }
</style>
